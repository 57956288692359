// Common
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

// RX
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { environment } from '@environment';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import { Constants } from '@modules/common/types/Constants';
import { MessageComponent } from '@modules/elements/components/message/message.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { Message } from '@modules/messages/types/message';
import { MessagesFilters } from '@modules/messages/types/messages-filters';

@Component({
  selector: 'app-messages2-list',
  templateUrl: './messages-list.component.html',
  styleUrl: './messages-list.component.less',
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class MessagesListComponent extends BaseStitchListComponent<Message> implements OnDestroy, OnChanges {
  @Input() override filters: Partial<MessagesFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() withConnections = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  @Output() readonly clickMessage = new EventEmitter<Message>();
  @Output() readonly openMessage = new EventEmitter<Message>();
  @Output() readonly doubleClickMessage = new EventEmitter<Message>();

  @ViewChildren(MessageComponent, { read: ElementRef<HTMLElement> })
  override itemElements: QueryList<ElementRef<HTMLElement>>;

  override items: StitchListItem<Message>[] = [];

  public readonly Constants = Constants;

  constructor(
    private messagesService: MessagesService,
    private messagesStateService: MailAppStateService,
    private linkedInfoService: LinkedInfoService,
    private injector: Injector,
  ) {
    super();

    merge(
      this.messagesStateService.getRefreshAll(),
      this.linkedInfoService.getRefresh(),
      this.messagesService.getRefresh(),
      interval(environment.messageFetchInterval),
    ).pipe(
      takeUntil(this.alive)
    ).subscribe(() => {
      this.refreshCurrentItems();
    });

    merge(
      this.messagesStateService.getRefreshAll(),
      this.messagesService.getRefresh(),
    ).pipe(
      takeUntil(this.alive)
    ).subscribe(() => {
      this.resetSelected.next();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes && !changes.filters.isFirstChange()) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: Message[], count: number }> {
    return this.messagesService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: this.withConnections, knotsSource: this.knotsSource }
    );
  }

  handleDoubleClickItem(message?: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.doubleClickMessage.emit(message);
    }
  }

  handleClickItem(message: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.clickMessage.emit(message);
    }
  }

  protected override getSectionValue(message: Message): unknown | undefined {
    if (this.filters.sortBy === 'date') {
      return message[this.filters.sortBy]
    }
    return undefined;
  }
}

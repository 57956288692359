<app-context-menu-heading>Sorting options:</app-context-menu-heading>

<app-context-menu-item
  [withCheckOption]="true"
  title="Title"
  [active]="state.sortBy === 'title'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('title')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Created Date"
  [active]="state.sortBy === 'createdAt'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('createdAt')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Updated Date"
  [active]="state.sortBy === 'updatedAt'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('updatedAt')"
></app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  [withCheckOption]="true"
  title="Pinned on Top"
  [active]="state.pinnedOnTop"
  (execute)="pinnedOnTop()"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged on Top"
  [active]="state.flaggedOnTop"
  (execute)="flaggedOnTop()"
></app-context-menu-item>

<app-context-menu-heading>Filters:</app-context-menu-heading>

<app-stitch-type-context-menu-item
  [withCheckOption]="true"
  title="Stitch"
  [state]="state.stitchedWith"
  (onSelect)="stitch($event)"
></app-stitch-type-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged"
  [active]="state.flagged"
  (execute)="flag()"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Pinned"
  [active]="state.pinned"
  (execute)="pin()"
></app-context-menu-item>

// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { CalendarAppModule } from '@modules/calendar-app/calendar-app.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { FilesModule } from '@modules/files/files.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessagesModule } from '@modules/messages/messages.module';
import { NotesModule } from '@modules/notes/notes.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TagsModule } from '@modules/tags/tags.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { HistoryComponent } from './components/history/history.component';
import { KnowledgeConnectionsSectionComponent } from './components/connections-section/connections-section.component';
import { KnowledgeKnotsSectionComponent } from './components/knots-section/knots-section.component';
import { KnowledgePanelComponent } from './components/knowledge-panel/knowledge-panel.component';
import { KnowledgeSectionComponent } from './components/section/section.component';
import { KnowledgeTagsSectionComponent } from './components/tags-section/tags-section.component';
import { StitchTypeContextMenuComponent } from './components/stitch-type-context-menu/stitch-type-context-menu.component';

// Directives
import { StitchTypeContextMenuDirective } from './directives/stitch-type-context-menu.directive';

@NgModule({
  imports: [
    AppCommonModule,
    CalendarAppModule,
    CommonModule,
    ConnectionsModule,
    ContactsModule,
    FilesModule,
    FormsModule,
    KnotsModule,
    MatIconModule,
    MatTooltipModule,
    MessagesModule,
    NotesModule,
    PopoverModule,
    SplitViewModule,
    TagsModule,
    TasksModule,
    UtilsModule
  ],
  declarations: [
    // Components
    HistoryComponent,
    KnowledgeConnectionsSectionComponent,
    KnowledgeKnotsSectionComponent,
    KnowledgePanelComponent,
    KnowledgeSectionComponent,
    KnowledgeTagsSectionComponent,
    StitchTypeContextMenuComponent,

    // Directives
    StitchTypeContextMenuDirective,
  ],
  exports: [
    KnowledgePanelComponent,
  ],
  providers: [

  ]
})
export class KnowledgeModule { }

// Common
import { ComponentRef, Directive, Input, OnInit, Output, EventEmitter, Injector } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ContactsContextMenuComponent } from '../components/contacts-context-menu/contacts-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appDPContactsContextMenu]'
})
export class ContactsContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appDPContactsContextMenuOpened = this.contextMenuOpened;
  @Output() appDPContactsContextMenuFilterChanged = new EventEmitter<VirtualFolder>();

  // Private
  private filterKey: VirtualFolder;

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<ContactsContextMenuComponent>) {
    componentRef.instance.filterKey = this.filterKey;
    componentRef.instance.change
      .pipe(
        takeUntil(this.alive)
      )
    .subscribe((filterKey: VirtualFolder) => {
      componentRef.instance.filterKey = filterKey;
      this.stateService.setState(StateKey.contactsDPSidebarFilters, filterKey);
    });
  }

  registerComponent() {
    return ContactsContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stateService.getState(StateKey.contactsDPSidebarFilters)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((filterKey: VirtualFolder) => {
        this.appDPContactsContextMenuFilterChanged.emit(filterKey);
        this.filterKey = filterKey;
      });
  }
}

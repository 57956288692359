// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// Modules
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { CommentsModule } from '@modules/comments/comments.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ElementsModule } from '../elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from './../linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TagsModule } from '@modules/tags/tags.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UtilsModule } from '@modules/utils/utils.module';
import { VirtualScrollModule } from '@modules/virtual-scroll/virtual-scroll.module';

// Services
import { FullFormService } from './services/full-form.service';

// Components
import { ContactsInlineListComponent } from './components/contacts-inline-list/contacts-inline-list.component';
import { FoldersListContextMenuComponent } from './components/folders-list-context-menu/folders-list-context-menu.component';
import { MessageComposeComponent } from './components/message-compose/message-compose.component';
import { MessageConnectionsComponent } from './components/message-connections/message-connections.component';
import { MessageFolderFormComponent } from './components/message-folder-form/message-folder-form.component';
import { MessageFolderSharedComponent } from './components/message-folder-shared/message-folder-shared.component';
import { MessageFoldersListComponent } from './components/folders-list/folders-list.component';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { MessageSharedComponent } from './components/message-shared/message-shared.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { MessagesListContainerComponent } from './components/list-container/list-container.component';
import { MessagesListContextMenuComponent } from './components/messages-list-context-menu/messages-list-context-menu.component';
import { MessagesMainViewComponent } from './components/messages-main-view/messages-main-view.component';
import { MessagesSideBarComponent } from '@modules/messages/components/side-bar/side-bar.component';
import { ParticipantContextMenuComponent } from './components/participant-context-menu/participant-context-menu.component';
import { SideBarFoldersComponent } from './components/side-bar-folders/side-bar-folders.component';
import { SideBarFoldersTreeComponent } from './components/side-bar-folders-tree/side-bar-folders-tree.component';
import { SidebarContextMenuComponent } from './components/sidebar-context-menu/sidebar-context-menu.component';
import { ScrollableComponent } from '@modules/common/components/scrollable/scrollable.component';
import { ListItemSeparatorComponent } from '@modules/common/components/list/list-items-separator/list-items-separator.component';
import { NoContentStubComponent } from '@modules/common/components/list/no-content-stub/no-content-stub.component';
import { ListLoadErrorComponent } from '@modules/common/components/list/list-load-error/list-load-error.component';
import { LoadingIndicatorComponent } from '@modules/common/components/loading-indicator/loading-indicator.component';
import { ListItemsCountComponent } from '@modules/common/components/list/list-items-count/list-items-count.component';

// Directives
import { MessagesFoldersListContextMenuDirective } from './directives/messages-folders-list-context-menu.directive';
import { MessagesListContextMenuDirective } from './directives/messages-list-context-menu.directive';
import { ParticipantContextMenuDirective } from './directives/participant-context-menu.directive';
import { SidebarContextMenuDirective } from './directives/sidebar-context-menu.directive';

// Pipes
import { FormatParticipantPipe } from './pipes/format-participant.pipe';
import { TitleByMessagesFilterPipe } from './pipes/title-by-messages-filter.pipe';
import { FormatSendLaterPipe } from './pipes/format-send-later.pipe';
import { KPMessagesListContextMenuComponent } from './components/messages-list-context-menu/kp-messages-list-context-menu/kp-messages-list-context-menu.component';

@NgModule({
  imports: [
    AppCommonModule,
    AttachmentsModule,
    BrowserAnimationsModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    EditorModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollModule,
    ScrollingModule,
    SplitViewModule,
    TagsModule,
    UserAvatarModule,
    UtilsModule,
    VirtualScrollModule,
    FormatParticipantPipe,
    ScrollableComponent,
    ListItemSeparatorComponent,
    NoContentStubComponent,
    ListLoadErrorComponent,
    LoadingIndicatorComponent,
    ListItemsCountComponent
  ],
  exports: [
    // Components
    MessageComposeComponent,
    MessageFolderFormComponent,
    MessageFoldersListComponent,
    MessageFormComponent,
    MessagesListComponent,
    MessagesListContainerComponent,
    MessagesMainViewComponent,
    MessagesSideBarComponent,
    // Directives
    MessagesFoldersListContextMenuDirective,
    MessagesListContextMenuDirective,
    // Pipes
    TitleByMessagesFilterPipe,
  ],
  declarations: [
    // Components
    ContactsInlineListComponent,
    FoldersListContextMenuComponent,
    MessageComposeComponent,
    MessageConnectionsComponent,
    MessageFolderFormComponent,
    MessageFolderSharedComponent,
    MessageFoldersListComponent,
    MessageFormComponent,
    MessageSharedComponent,
    MessagesListComponent,
    MessagesListContainerComponent,
    MessagesListContextMenuComponent,
    KPMessagesListContextMenuComponent,
    MessagesMainViewComponent,
    MessagesSideBarComponent,
    ParticipantContextMenuComponent,
    SideBarFoldersComponent,
    SideBarFoldersTreeComponent,
    SidebarContextMenuComponent,

    // Directives
    MessagesFoldersListContextMenuDirective,
    MessagesListContextMenuDirective,
    ParticipantContextMenuDirective,
    SidebarContextMenuDirective,
    // Pipes
    TitleByMessagesFilterPipe,
    FormatSendLaterPipe,
  ],
  providers: [
    FullFormService,
    FormatParticipantPipe,
  ]
})
export class MessagesModule { }

// Common
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { StitchType } from '@modules/common/types/stitch-type';
import { Stitch } from '@modules/common/types/stitch';
import { Task } from '@modules/tasks/types/task';
import { Note } from '@modules/notes/types/note';
import { Contact } from '@modules/contacts/types/contact';
import { File } from '@modules/files/types/file';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Constructor } from '@modules/common/types/constructor';
import { Message } from '@modules/messages/types/message';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FilesService } from '@modules/files/services/files.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// RxJs
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-move-to',
  templateUrl: './move-to.component.html',
  styleUrls: ['./move-to.component.less']
})
export class MoveToComponent implements OnChanges {

  // Inputs
  @Input() items: Stitch[];
  @Input() appearance: 'move' | 'copy';

  // Outputs
  @Output() close = new EventEmitter();

  // Public
  public parentItemType: StitchType;
  public suggestions: AutocompleteFactory<any>;
  public inputControl = new UntypedFormControl();
  public displayedItems: Stitch[] = [];
  public scrollShadowTop = false;
  public scrollShadowBottom = false;

  /**
   * Constructor
   */

  constructor(
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private notebooksService: NotebooksService,
    private notesService: NotesService,
    private groupsService: GroupsService,
    private contactsService: ContactsService,
    private foldersService: FoldersService,
    private filesService: FilesService,
    private toasterService: ToasterService,
    private messageFoldersService: MessageFoldersService,
    private messagesService: MessagesService
  ) {}

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges): void {
    if ('items' in changes) {
      if (this.items[0] instanceof Task) {
        this.parentItemType = this.items[0].parentId ?
          StitchType.task : StitchType.project;
        this.suggestions = (this.items[0].parentId ?
          this.tasksService : this.projectsService)
          .getAutocompleteSuggestions();
      } else if (this.items[0] instanceof Note) {
        this.parentItemType = StitchType.notebook;
        this.suggestions = this.notebooksService.getAutocompleteSuggestions();
      } else if (this.items[0] instanceof Contact) {
        this.parentItemType = StitchType.group;
        this.suggestions = this.groupsService.getAutocompleteSuggestions();
      } else if (this.items[0] instanceof File) {
        this.parentItemType = StitchType.folder;
        this.suggestions = this.foldersService.getAutocompleteSuggestions();
      } else if (this.items[0] instanceof  Message) {
        this.parentItemType = StitchType.messageFolder;
        this.suggestions = this.messageFoldersService.getAutocompleteSuggestions();
      }
    }
  }

  /**
   * Actions
   */

  handleSelect(selectedItem: DropdownSelectItem<Stitch>) {
    this.inputControl.setValue('');

    if (this.displayedItems.some(({ id }) => id === selectedItem.source?.id)) {
      return;
    }

    if (selectedItem.source) {
      this.displayedItems.push(selectedItem.source);
    }
  }

  handleQuickAfterSave(savedItem: Stitch) {
    this.displayedItems.push(savedItem);
  }

  removeItem(removedItem: Stitch) {
    this.displayedItems = this.displayedItems.filter(({ id }) => id !== removedItem.id);
  }

  removeAll() {
    this.displayedItems = [];
  }

  handleSave() {
    combineLatest([
      ...this.creationFactory()
    ])
      .subscribe(() => {
        this.toasterService
          .show({
            text: `${ this.items[0]?.constructor?.name }${ this.items.length > 1 ? 's were' : ' was' } ${ this.appearance === 'move' ? ' moved' : ' copied'}.`
          });
      });

    // TODO something's wrong here
    this.close.emit();
  }

  handleCancel() {
    this.close.emit();
  }

  /**
   * Helpers
   */

  private creationFactory(): Observable<unknown>[] {
    let service: BaseStitchService<Stitch, StitchFilters<Stitch>>;
    let parentIdType: string;
    const additionalProps: any = { noNotification: true };

    switch (this.parentItemType) {
      case StitchType.task:
        service = this.tasksService;
        parentIdType = 'parentId';
        break;
      case StitchType.project:
        service = this.tasksService;
        parentIdType = 'projectId';
        break;
      case StitchType.notebook:
        service = this.notesService;
        parentIdType = 'notebookId';
        break;
      case StitchType.folder:
        service = this.filesService;
        parentIdType = 'folderId';
        break;
      case StitchType.group:
        service = this.contactsService;
        parentIdType = 'groupId';
        break;
      case StitchType.messageFolder:
        service = this.messagesService;
        parentIdType = 'folderId';
        additionalProps.noSend = true;
        break;
    }

    return this.displayedItems
      .map(({ id }) => {
        const ids = this.items.map(({ id }) => id)

        return this.appearance === 'move'
          ? service.bunchUpdate({ ids }, { [parentIdType]: id, ...additionalProps }, { toast: false })
          : service.duplicate(ids, id, { toast: false })
      })
  }
}

// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { NotebooksListContextMenuComponent } from '../components/notebooks-list-context-menu/notebooks-list-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { NotebooksListState } from '../types/notebooks-list-state';

// RX
import { startWith, takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

@Directive({
  selector: '[appNotebooksListContextMenu]'
})
export class NotebooksListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {

  // Inputs
  @Input() appNotebooksListContextMenuStateKey: StateKey;
  @Input() appNotebooksListContextMenuWithAdvancedSearch: boolean;
  @Input() appNotebooksListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  // Outputs
  @Output() appNotebooksListContextMenuChange = new EventEmitter<NotebooksListState>();

  // Private
  private state: NotebooksListState;

  constructor (
    private stateService: StateService,
    private searchService: AdvancedSearchService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<NotebooksListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appNotebooksListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appNotebooksListContextMenuWithScore;
  }

  registerComponent() {
    return NotebooksListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.state = new NotebooksListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appNotebooksListContextMenuStateKey,
      withAdvanced: this.appNotebooksListContextMenuWithAdvancedSearch
    });

    this.state.changes()
      .pipe(
        startWith(null),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appNotebooksListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.state.isDefault()) {
          this.state.resetToDefault();
        }
      });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-loading-indicator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`:host div { text-align: center; }`],
  template: `<div><ng-content></ng-content></div>`,
})
export class LoadingIndicatorComponent {}

// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { EventContextMenuComponent } from '../components/context-menus/event-context-menu/event-context-menu.component';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appEventContextMenu]'
})
export class EventContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appEventContextMenu: CalendarEvent;
  @Input() appEventContextMenuDisabled = false;
  @Input() appEventContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appEventContextMenuOpened = this.contextMenuOpened;
  @Output() appEventContextMenuOpenEvent = new EventEmitter;

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appEventContextMenuDisabled;

    if (this.appEventContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return EventContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<EventContextMenuComponent>) {
    componentRef.instance.event = this.appEventContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appEventContextMenuOpenEvent.emit();
      });
  }
}

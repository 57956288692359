import { Component } from "@angular/core";
import { MessagesListContextMenuComponent } from "../messages-list-context-menu.component";

@Component({
  standalone: false,
  selector: 'app-kp-messages-list-context-menu',
  templateUrl: './kp-messages-list-context-menu.component.html',
  styleUrl: '../messages-list-context-menu.component.less'
})
export class KPMessagesListContextMenuComponent extends MessagesListContextMenuComponent {

}
// Types
import { NotebooksListState } from './notebooks-list-state';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { VirtualFolder } from './virtual-folder';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { Notebook } from './notebook';

export class NotebooksFilters extends StitchFilters<Notebook> {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  createdAt?: Date;
  updatedAt?: Date;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'score' | 'sharedViaLinkAt';

  constructor (filters?: Like<NotebooksFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.sortBy = filters?.sortBy;
  }

  applyListState(state: NotebooksListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }
    if (this.createdAt) { result['created_at'] = this.createdAt.toISOString(); }
    if (this.updatedAt) { result['updated_at'] = this.updatedAt.toISOString(); }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.notes;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = state.title;
    this.esBody = state.description + ' ' + advanced.notes.body;
    this.createdAt = state.createdAt;
    this.updatedAt = state.updatedAt;
    this.fromTime = state.dateRangeFrom;
    this.toTime = state.dateRangeTo;

    return this;
  }

  
  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      case 'all_notes':
      case 'all_notebooks':
      case 'upcoming':
      case 'recent':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}

// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { MessageContextMenuComponent } from '../components/context-menus/message-context-menu/message-context-menu.component';

// Types
import { Message } from '@modules/messages/types/message';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMessageContextMenu]'
})
export class MessageContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appMessageContextMenu: Message;
  @Input() appMessageContextMenuDisabled = false;
  @Input() appMessageContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appMessageContextMenuOpened = this.contextMenuOpened;
  @Output() appMessageContextMenuOpenMessage = new EventEmitter;

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appMessageContextMenuDisabled;

    if (this.appMessageContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return MessageContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<MessageContextMenuComponent>) {
    componentRef.instance.message = this.appMessageContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appMessageContextMenuOpenMessage.emit();
      });
  }
}

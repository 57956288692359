import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Constants } from '@modules/common/types/Constants';
import { debounceTime, filter, fromEvent, merge, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-scrollable',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet
  ],
  templateUrl: "./scrollable.component.html",
  styleUrl: './scrollable.component.less'
})
export class ScrollableComponent<I> implements AfterViewInit, OnChanges, OnDestroy {
  @Input({required: true}) loading: boolean;
  @Input({required: true}) canAutoLoad?: boolean;
  @Input() autoloadThreshold: number = 100;
  @Input() debounceTime: number = Constants.DEBOUNCE_TIME_SCROLL;

  @Input() headerTemplate?: TemplateRef<void>;
  @Input() aboveContentTemplate?: TemplateRef<void>;
  @Input() belowContentTemplate?: TemplateRef<void>;
  @Input() footerTemplate?: TemplateRef<void>;

  @Output() readonly loadNext = new EventEmitter<void>();

  @ViewChild('scroller', { read: ElementRef<HTMLElement> }) scroller: ElementRef<HTMLElement>;

  private readonly loadNextCheck: Subject<void> = new Subject();
  private readonly destroyed: Subject<void> = new Subject();

  ngOnChanges(changes: SimpleChanges): void {
    if ("loading" in changes && !this.loading) {
      this.loadNextCheck.next();
    }
  }

  ngAfterViewInit() {
    merge(
      this.loadNextCheck,
      fromEvent(this.scroller.nativeElement, 'scroll')
    )
      .pipe(
        filter(() => this.canAutoLoad),
        debounceTime(this.debounceTime),
        filter(() => {
          const { scrollTop, scrollHeight, clientHeight } = this.scroller.nativeElement
          return scrollHeight - scrollTop <= clientHeight + this.autoloadThreshold
        }),
        takeUntil(this.destroyed),
      )
      .subscribe(() => {
        this.loadNext.emit();
      })
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
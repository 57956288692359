// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type Task } from './task';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export type TaskSortableFields = 'title' | 'date' | 'due-date' | 'priority' | 'position' | 'score' | 'issue-key';

export class TasksListState extends BaseStitchListState<Task, TaskSortableFields> {
  @OnChange('onPropertyChange') sortBy?: TaskSortableFields;
  @OnChange('onPropertyChange') today?: boolean;
  @OnChange('onPropertyChange') next7days?: boolean;

  // Private
  private withPosition = false;

  constructor(
    { searchService, stateService, stateKey, withAdvanced, withPosition }:
    {
      searchService?: AdvancedSearchService,
      stateService?: StateService,
      stateKey?: StateKey,
      withAdvanced?: boolean,
      withPosition?: boolean
    }
  ) {
    super({ searchService, stateService, stateKey, withAdvanced });
    this.withPosition = withPosition;
  }

  protected override applyState(state?: Like<TasksListState>) {
    super.applyState(state);

    this.sortBy = state?.sortBy || 'title';
    this.today = !!state?.today;
    this.next7days = !!state?.next7days;

    if (this.withPosition && !state?.sortBy) {
      this.sortBy = 'position';
      this.sortOrder = 'asc';
    }
  }

  protected override onPropertyChange(attribute: keyof this) {
    if (
      (attribute === 'from' && this.from) ||
      (attribute === 'to' && this.to)
    ) {
      this.today = false;
      this.next7days = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
      this.next7days = false;
    }

    if (attribute === 'next7days') {
      this.from = null;
      this.to = null;
      this.today = false;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today,
      next7days: this.next7days,
    });
  }

  isDefault(): boolean {
    return (
      super.isDefault() &&
      this.sortBy === 'title' &&
      this.sortOrder === 'asc' &&
      !this.today &&
      !this.next7days
    );
  }

  protected override syncFromAS({ tasking }: AdvancedSearchState) {
    this.stitchedWith = tasking.stitched;
    this.from = tasking.dateRangeFrom;
    this.to = tasking.dateRangeTo;
  }

  protected override syncToAS() : void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.tasking;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;

    this.searchService.setState(searchState);
  }
}

import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { BaseStitchListState } from "@modules/common/types/base-stitch-list-state";
import { type Stitch } from "@modules/common/types/stitch";
import { map, Observable, startWith } from "rxjs";

@Injectable()
export abstract class ListStateService<S extends Stitch, STATE extends BaseStitchListState<S>> implements OnDestroy {

  public abstract readonly state: STATE;

    value() : Observable<STATE> {
        return this.state.changes().pipe(
          map(() => this.state),
          startWith(this.state)
        );
    }

    ngOnDestroy() {
        this.state.detach();
    }

    protected static prepareProviders<CONFIG extends {}>(token: InjectionToken<CONFIG>, config: CONFIG): any[] {
        return [
            {
                provide: token,
                useValue: config
            },
            {
                provide: ListStateService,
                useClass: this,
                multi: true
            },
            {
              provide: this,
              useClass: this
          }
        ];
    }
}
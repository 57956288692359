// Utils
import { simpleObjectHash } from '../utils/base';

// RX
import { Observable, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

// Types
import { BaseSearchResponse } from '../types/base-search-response';
import { FeedbackConfig } from '../types/base-service-types';
import { Filters } from '../types/filters';
import { HttpErrorResponse } from '@angular/common/http';

// Services
import { BaseService } from './base.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

export abstract class BaseRestService<T, TFilters extends Filters> extends BaseService {
  private searchCache: { [key: string]: T[] } = {};

  constructor(protected toaster: ToasterService) {
    super();
  }

  protected handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text: error?.error?.message || error?.error?.error || error?.message });
    return throwError(() => error);
  }

  abstract search(filters: Partial<Filters>, config?: object): Observable<BaseSearchResponse<T>>;

  abstract create(instance: T, feedbackConfig?: FeedbackConfig, ...rest: unknown[]): Observable<T>;

  abstract update(instance: T, feedbackConfig?: FeedbackConfig): Observable<T>;

  findAll(
    filters?: Partial<TFilters>,
    config?: { cache?: boolean, [key: string]: any },
    page = 0,
    memo: T[] = []
  ): Observable<T[]> {
    const hashKey = simpleObjectHash(filters);

    if (config?.cache && this.searchCache[hashKey]) {
      return of(this.searchCache[hashKey]);
    }

    const limit = filters?.limit || 100;

    return this.search({ ...filters, limit, offset: page * limit }, config)
      .pipe(
        switchMap(({ count, items }) => {
          const allItems = memo.concat(items);

          return page * limit + limit < count
            ? this.findAll(filters, config, page + 1, allItems)
            : of(allItems);
        }),
        tap(items => {
          this.searchCache[hashKey] = items;
        })
      );
  }

  upsert(instance: T, config?: FeedbackConfig): Observable<T> {
    return instance['id'] && instance['id'] !== 'temp' ? this.update(instance, config) : this.create(instance, config);
  }
}

// Common
import { ChangeDetectorRef, Component, EventEmitter, inject, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Services
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { StateService } from '@modules/settings/services/state.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { KPListType } from '@modules/knowledge/types/kp-list-type';
import { StateKey } from '@modules/settings/types/state-key';
import { Tag } from '@modules/tags/types/tag';

import { EventsListState } from '@modules/calendar-app/types/events-list-state';
import { ManageListState as ConnectionsListState } from '@modules/connections/types/manage-list-state';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { FilesListState } from '@modules/files/types/files-list-state';
import { FoldersListState } from '@modules/files/types/folders-list-state';
import { FoldersListState as MessageFoldersListState } from '@modules/messages/types/folders-list-state';
import { MessagesListState } from '@modules/messages/types/messages-list-state';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { ProjectsListState } from '@modules/tasks/types/projects-list-state';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';

import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { SortOrder } from '@modules/common/types/sort-order';
import { ConnectionsFilters } from '@modules/connections/types/connections-filters';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { FilesFilters } from '@modules/files/types/files-filters';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { KnotSource } from '@modules/knots/types/knot-source';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { MessageFolderListStateService } from '@modules/messages/services/message-folder-list-state-holder.service';
import { MessageListStateService } from '@modules/messages/services/message-list-state-holder.service';
import { FoldersFilters as MessageFoldersFilters } from '@modules/messages/types/folders-filters';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

type AllFiltersType = MessageFoldersFilters | MessagesFilters | EventsFilters | ProjectsFilters |
  TasksFilters | NotebooksFilters | NotesFilters | GroupsFilters | ContactsFilters | FilesFilters | FoldersFilters | ConnectionsFilters;

@Component({
  selector: 'app-knowledge-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.less'],
  providers: [
    MessageListStateService.providers({
      defaultSort: {
        by: 'score',
        order: SortOrder.DESC
      }
    }),
    MessageFolderListStateService.providers()
  ]
})
export class KnowledgeSectionComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() title: string;
  @Input() stateKey: StateKey;
  @Input() tags: Tag[];
  @Input() knots: Knot[];
  @Input() connections: Connection[];

  // Output
  @Output() itemClick = new EventEmitter<Stitch>();
  @Output() typeChange = new EventEmitter<KPListType>();
  @Output() filterChange = new EventEmitter<void>();

  public KPListType = KPListType;
  public menuOpened = false;
  public type: KPListType = KPListType.message;
  public state = {};
  public knotsSource: KnotSource;

  private readonly alive = new Subject<void>();
  private selectedStitchItem: Stitch;
  private filters: AllFiltersType;

  private readonly messageListStateService = inject(MessageListStateService, { self: true });
  private readonly messageFolderListStateService = inject(MessageFolderListStateService, { self: true });

  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private kpService: KnowledgePanelService,
    private stateService: StateService,
    private injector: Injector
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.state = {
      messageFolder: new MessageFoldersFilters({ withEmpty: true }),
      message: new MessagesFilters({ withEmpty: true }),
      event: new EventsFilters({ withEmpty: true }),
      project: new ProjectsFilters({ withEmpty: true }),
      task: new TasksFilters({ withEmpty: true }),
      notebook: new NotebooksFilters({ withEmpty: true }),
      note: new NotesFilters({ withEmpty: true }),
      group: new GroupsFilters({ withEmpty: true }),
      contact: new ContactsFilters({ withEmpty: true }),
      folder: new FoldersFilters({ withEmpty: true }),
      file: new FilesFilters({ withEmpty: true }),
      connection: new ConnectionsFilters({ withEmpty: true }),
    };

    this.stateService.getState(StateKey.kpKnotsState)
      .pipe(takeUntil(this.alive))
      .subscribe((state: ManageListState) => {
        this.knotsSource = state?.filters?.source;
      });

    this.messageListStateService.value()
      .pipe(
        filter(() => this.type === KPListType.message),
        takeUntil(this.alive)
      )
      .subscribe((state: MessagesListState) => {
        this.handleFilter(state);
      });

    this.messageFolderListStateService.value()
      .pipe(
        filter(() => this.type === KPListType.messageFolder),
        takeUntil(this.alive)
      )
      .subscribe((state: MessageFoldersListState) => {
        this.handleFilter(state);
      });

    this.kpService.getSelectedStitchItem()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((stitchItem: Stitch) => {
        this.selectedStitchItem = stitchItem;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tags' in changes || 'knots' in changes || 'connections' in changes) {
      this.updateFilters();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFilter(
    listState: MessageFoldersListState | MessagesListState | EventsListState |
      ProjectsListState | TasksListState | NotebooksListState | NotesListState | GroupsListState | ContactsListState |
      FoldersListState | FilesListState | ConnectionsListState
  ) {
    this.filterChange.emit();

    const filtersObjectByType = {
      [KPListType.messageFolder]: MessageFoldersFilters,
      [KPListType.message]: MessagesFilters,
      [KPListType.event]: EventsFilters,
      [KPListType.project]: ProjectsFilters,
      [KPListType.task]: TasksFilters,
      [KPListType.notebook]: NotebooksFilters,
      [KPListType.note]: NotesFilters,
      [KPListType.group]: GroupsFilters,
      [KPListType.contact]: ContactsFilters,
      [KPListType.folder]: FoldersFilters,
      [KPListType.file]: FilesFilters,
      [KPListType.connection]: ConnectionsFilters
    };

    this.filters = new filtersObjectByType[this.type]();

    if (this.filters instanceof ConnectionsFilters) {
      // TODO Connections will be removed from this component
    } else {
      this.filters.applyListState(listState as any);
    }

    this.updateFilters();
  }

  handleClickItem(item: Stitch) {
    this.itemClick.emit(item);
  }

  handleDoubleClickItem(item: Stitch) {
    this.modalService.openFullForm(item, this.injector);
  }

  handleTypeChange(type: KPListType) {
    this.type = type;
    this.typeChange.emit(type);
  }

  private updateFilters() {
    const resultsFilters = {
      ...this.filters
    };
    //resultsFilters.esAnalyzer = 'english';
    //resultsFilters.esMultiMatchType = 'phrase';
    resultsFilters.query_boost = 0.1;
    resultsFilters.tags_boost = 20;
    resultsFilters.knots_boost = 15;
    resultsFilters.connections_boost = 10;

    resultsFilters.query = [];

    if (this.tags.length) {
      resultsFilters.tags = this.tags;
      resultsFilters.query.push(...this.tags.map(i => i.name));
    }
    if (this.knots.length) {
      resultsFilters.knots = this.knots;
      resultsFilters.query.push(...this.knots.map(i => i.name));
    }
    if (this.connections.length) {
      resultsFilters.connections = this.connections;
      resultsFilters.query.push(...this.connections.map(i => i.name));
    }

    resultsFilters.withEmpty = resultsFilters.query.length === 0;

    if (this.type === this.selectedStitchItem?.getStitchType() as unknown as KPListType) {
      resultsFilters.exceptIds = [this.selectedStitchItem.id];
    }

    this.state[this.type] = resultsFilters;
    this.changeDetector.detectChanges();
  }
}

// Types
import { Application } from '@modules/common/types/application';
import { FilesListState } from './files-list-state';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { VirtualFolder } from './virtual-folder';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { isNil } from '@modules/common/utils/base';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { File } from './file';

export class FilesFilters extends StitchChildFilters<File> {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  fromSize?: number;
  toSize?: number;
  mimeTypes?: string[];
  uploadDate?: Date;
  openedDate?: Date;
  receivedDate?: Date;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'size' | 'score' | 'sharedViaLinkAt';

  constructor (filters?: Like<FilesFilters>) {
    super(filters);
    this.fromSize = filters?.fromSize;
    this.fromTime = filters?.fromTime;
    this.mimeTypes = Array.isArray(filters?.mimeTypes) ? filters.mimeTypes : [];
    this.openedDate = filters?.openedDate;
    this.receivedDate = filters?.receivedDate;
    this.sortBy = filters?.sortBy;
    this.toSize = filters?.toSize;
    this.toTime = filters?.toTime;
    this.uploadDate = filters?.uploadDate;
  }

  applyListState(state: FilesListState) {
    const today = new Date();

    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;
    if (state?.today) {
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }
    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    this.fromSize = state?.fromSize;
    this.toSize = state?.toSize;
    this.mimeTypes = state?.mimeTypes;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['folders_ids[]'] = this.containersIds; }
    if (this.originsIds) { result['folders_ids[]'] = this.originsIds; }
    if (!isNil(this.noContainer)) { result['no_folder'] = this.noContainer + ''; }

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }
    if (this.fromSize) { result['from_size'] = this.fromSize; }
    if (this.toSize) { result['to_size'] = this.toSize; }
    if (this.mimeTypes) { result['mime_types[]'] = this.mimeTypes.map(i => i.split('|')).flat(); }
    if (this.uploadDate) { result['upload_date'] = this.uploadDate.toISOString(); }
    if (this.openedDate) { result['opened_date'] = this.openedDate.toISOString(); }
    if (this.receivedDate) { result['received_date'] = this.receivedDate.toISOString(); }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.files);

    const state = advanced.files;

    this.uploadDate = state.uploadDate;
    this.openedDate = state.openedDate;
    this.receivedDate = state.receivedDate;
    this.esTitle = state.title;
    this.esBody = state.description;
    this.fromSize = state.fileSize.from;
    this.toSize = state.fileSize.to;
    this.mimeTypes = state.fileType;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;
    this.noContainer = null;

    switch (folder) {
      case 'archived':
        this.archived = true;
        break;
      case 'deleted':
        this.deleted = true;
        break;
      case 'all_files':
        this.noContainer = true;
        break;
      case 'upcoming':
      case 'recent':
      case 'all_folders':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}

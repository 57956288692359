// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { MessageFolderContextMenuComponent } from '../components/context-menus/message-folder-context-menu/message-folder-context-menu.component';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMessageFolderContextMenu]'
})
export class MessageFolderContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appMessageFolderContextMenu: MessageFolder;
  @Input() appMessageFolderContextMenuDisabled = false;
  @Input() appMessageFolderContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appMessageFolderContextMenuOpened = this.contextMenuOpened;
  @Output() appMessageFolderContextMenuOpenFolder = new EventEmitter<void>();

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appMessageFolderContextMenuDisabled;

    if (this.appMessageFolderContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return MessageFolderContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<MessageFolderContextMenuComponent>) {
    componentRef.instance.folder = this.appMessageFolderContextMenu;
    componentRef.instance.open
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.appMessageFolderContextMenuOpenFolder.emit();
      });
  }
}

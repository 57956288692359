import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-list-items-separator',
    template: '<ng-content></ng-content>',
    styleUrl: './list-items-separator.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemSeparatorComponent {}

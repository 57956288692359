// Types
import { FeedbackConfig } from '../types/base-service-types';
import { StitchChildFilters } from '../types/stitch-child-filters';
import { ChildStitch } from '../types/child-stitch';

// RX
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Services
import { BaseStitchService } from './base-stitch.service';

export abstract class BaseStitchChildService<T extends ChildStitch, TFilters extends StitchChildFilters<T>> extends BaseStitchService<T, TFilters> {

  reorder(
    items: T[],
    config?: FeedbackConfig
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      this.url + 'reorder',
      {
        items: items.map(({ id, position, containerId, sectionId, parentId }) =>
          ({ id, position, containerId, sectionId, parentId }))
      }
    )
      .pipe(
        tap(({ success }) => {
          success && config?.emit && this.forceRefresh();
        }),
        map(({ success }) => success)
      );
  }
}

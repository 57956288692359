<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByMessagesFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'messages'"
          svgIcon="filters"
          appMessagesListContextMenu
        />
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'folders'"
          svgIcon="filters"
          appMessagesFoldersListContextMenu
          [appMessagesFoldersListContextMenuWithAdvancedSearch]="true"
        />
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    ></app-tabs>
  </div>

  <app-quick-form
    *ngIf="
      selectedTab === 'messages' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Message"
  >
    <app-message-quick-form
      [message]="quickMessage"
      [autofocus]="true"
      (more)="handleNewMessage($event)"
    ></app-message-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedTab === 'folders' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Folder"
  >
    <app-message-folder-quick-form
      [folder]="quickFolder"
      [autofocus]="true"
      (more)="handleNewFolder($event)"
    ></app-message-folder-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-messages2-list
      *ngIf="selectedTab === 'messages' && messagesFilters"
      [filters]="messagesFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openMessage)="handleClickMessage($event)"
      (clickMessage)="handleClickMessage($event)"
      (doubleClickMesssage)="handleDoubleClickMessage($event)"
    ></app-messages2-list>

    <app-message-folders-list
      *ngIf="selectedTab === 'folders'"
      [filters]="foldersFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openFolder)="handleClickFolder($event)"
      (openMesssage)="handleClickMessage($event)"
      (clickFolder)="handleClickFolder($event)"
      (doubleClickFolder)="handleDoubleClickFolder($event)"
    ></app-message-folders-list>
  </div>
</div>
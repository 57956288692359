// Common
import { Injectable } from '@angular/core';

// Types
import { CollapsedStateKey } from '../types/collapsed-state';
import { GlobalState } from '../types/global-state';
import { State } from '../types/state';
import { StateKey } from '../types/state-key';

// RX
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class StateService {
  // Private
  private currentStates: BehaviorSubject<GlobalState>;
  private defaultStates = new GlobalState();
  private state = new BehaviorSubject<State>({});

  /**
   * Settings
   */

  set isTextEditorToolbarExpanded(value: boolean) { this.updateStates({ isTextEditorToolbarExpanded: value }); }
  get isTextEditorToolbarExpanded(): boolean { return this.currentStates.value.isTextEditorToolbarExpanded; }

  setCollapsed(key: CollapsedStateKey, value: boolean) {
    this.updateStates({
      collapsed: {
        ...this.currentStates.value.collapsed,
        [key]: value
      }
    });
  }

  getCollapsed(key: CollapsedStateKey): Observable<boolean> {
    return this.currentStates
      .asObservable()
      .pipe(
        map((state: GlobalState) => state.collapsed[key]),
        distinctUntilChanged()
      );
  }

  /**
   * Constructor
   */

  constructor() {
    this.currentStates = new BehaviorSubject<GlobalState>({
      ...this.defaultStates,
      ...this.getSavedStates()
    });
    this.currentStates.subscribe((states: GlobalState) => this.saveStates(states));

    try {
      this.state.next(JSON.parse(localStorage.getItem('stitchState')) || {
          [StateKey.messagesDPSidebarFilters]: 'all_messages',
          [StateKey.eventsDPSidebarFilters]: 'all_events',
          [StateKey.tasksDPSidebarFilters]: 'all_tasks',
          [StateKey.notesDPSidebarFilters]: 'all_notes',
          [StateKey.contactsDPSidebarFilters]: 'all_contacts',
          [StateKey.filesDPSidebarFilters]: 'all_files',
        });
    } catch (e) {

    }
  }

 /**
  * @deprecated Use LSItem, LSString, LSBoolean, LSNumber, etc approach from 'src/app/decorators/local-storage.decorator'
  */
  getState(key: StateKey): Observable<unknown> {
    return this.state
      .pipe(
        map((currentState: State) => currentState[key]),
        distinctUntilChanged()
      );
  }

 /**
  * @deprecated Use LSItem, LSString, LSBoolean, LSNumber, etc approach from 'src/app/decorators/local-storage.decorator'
  */
  getStateSync(key: StateKey) {
    return this.state.value[key];
  }

 /**
  * @deprecated Use LSItem, LSString, LSBoolean, LSNumber, etc approach from 'src/app/decorators/local-storage.decorator'
  */
  setState(key: StateKey, value: unknown) {
    const nextState = { ...this.state.value, [key]: value };
    this.state.next(nextState);
    localStorage.setItem('stitchState', JSON.stringify(nextState));
  }

  private getSavedStates(): GlobalState {
    const savedState = localStorage.getItem('app.states');
    if (savedState) {
      try {
        return new GlobalState(JSON.parse(savedState));
      } catch (e) {
        console.warn('Can not parse JSON from localStore. ', e);
      }
    }
    return {};
  }

  private saveStates(states: GlobalState) {
    localStorage.setItem('app.states', JSON.stringify(states));
  }

  private updateStates(states: GlobalState) {
    this.currentStates.next({
      ...this.currentStates.value,
      ...states
    });
  }
}

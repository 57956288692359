// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { TasksListContextMenuComponent } from '../components/tasks-list-context-menu/tasks-list-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { TasksListState } from '../types/tasks-list-state';

// RX
import { startWith, takeUntil } from 'rxjs/operators';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appTasksListContextMenu]'
})
export class TasksListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {

  // Inputs
  @Input() appTasksListContextMenuStateKey: StateKey;
  @Input() appTasksListContextMenuWithAdvancedSearch: boolean;
  @Input() appTasksListContextMenuWithScore: boolean;
  @Input() appTasksListContextMenuWithPosition: boolean;
  @Input() appTasksListContextMenuWithSidebarFilters = false;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appTasksListContextMenuChange = new EventEmitter<TasksListState>();

  private state: TasksListState;

  constructor (
    private searchService: AdvancedSearchService,
    private stateService: StateService,
    injector: Injector
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<TasksListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appTasksListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appTasksListContextMenuWithScore;
    componentRef.instance.withSidebarFilters = this.appTasksListContextMenuWithSidebarFilters;
  }

  registerComponent() {
    return TasksListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.state = new TasksListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appTasksListContextMenuStateKey,
      withAdvanced: this.appTasksListContextMenuWithAdvancedSearch,
      withPosition: this.appTasksListContextMenuWithPosition
    });

    this.state.changes()
      .pipe(
        startWith(null),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appTasksListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.state.isDefault()) {
          this.state.resetToDefault();
        }
      });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}

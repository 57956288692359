// Common
import { ComponentType } from '@angular/cdk/overlay';
import { AfterViewInit, ComponentRef, Directive, ElementRef, inject, Injector, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { PopoverDirective } from '@modules/popover/directives/popover.directive';

// Services
import { PopoverService } from '@modules/popover/services/popover.service';
import { contextMenuStateIndicator } from '../types/context-menu-state-indicator';

// RX
import { Subject } from 'rxjs';

@Directive({ selector: 'contextMenu' })
export abstract class ContextMenuDirective extends PopoverDirective implements OnInit, AfterViewInit, OnDestroy {
  @Output() contextMenuOpened = this.stchPopoverVisibleChange;

  protected readonly isDefaultStateObservable = inject(contextMenuStateIndicator, {
    optional: true
  });

  protected readonly closeSubject = new Subject<void>();

  constructor(
    injector: Injector,
  ) {
    super(
      injector.get(ElementRef),
      injector.get(PopoverService),
      injector.get(NgZone),
      injector
    );
  }

  ngOnInit(): void {
    this.stchPopoverComponent = this.registerComponent();
    this.stchPopoverComponentDelegate = (ref: ComponentRef<any>) => this.registerInstance(ref);

    this.stchPopoverTrigger ??= 'contextmenu';
    this.stchPopoverPlacement ??= 'mouseBottomRight';

    this.stchPopoverArrow = false;
    this.stchPopoverShowUntil = this.closeSubject.asObservable();

    this.stchPopoverCustomStyles = { padding: '4px 0' };
  }

  abstract registerComponent(): ComponentType<any>;

  abstract registerInstance(componentRef: ComponentRef<any>): void;

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closeSubject.next();
    this.closeSubject.complete();
  }
}

// Common
import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { MessagesListContextMenuComponent } from '../components/messages-list-context-menu/messages-list-context-menu.component';
import { KPMessagesListContextMenuComponent } from '../components/messages-list-context-menu/kp-messages-list-context-menu/kp-messages-list-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { startWith, takeUntil } from 'rxjs/operators';

// Services
import { MessageListStateService } from '../services/message-list-state-holder.service';

@Directive({
  selector: '[appMessagesListContextMenu]'
})
export class MessagesListContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() appMessagesListContextMenuLocation: 'messages' | 'knowledge_panel' = 'messages';
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  private readonly messageListStateService = inject(MessageListStateService);

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<MessagesListContextMenuComponent>) {
    componentRef.instance.state = this.messageListStateService.state;
  }

  registerComponent() {
    switch (this.appMessagesListContextMenuLocation) {
      case 'knowledge_panel':
        return KPMessagesListContextMenuComponent;
      case 'messages':
        return MessagesListContextMenuComponent;
      default:
        console.warn(`Unknown context menu location: ${this.appMessagesListContextMenuLocation}`);
        return MessagesListContextMenuComponent;
    }
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.messageListStateService.state.changes().pipe(
      startWith(null),
      takeUntil(this.alive)
    ).subscribe(() => {
      this.isDefaultStateObservable?.next(this.messageListStateService.state.isDefault());
    })

    this.isDefaultStateObservable?.pipe(
      takeUntil(this.alive)
    ).subscribe(isDefault => {
      if (isDefault && !this.messageListStateService.state.isDefault()) {
        this.messageListStateService.state.resetToDefault();
      }
    })
  }
}

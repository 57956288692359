import { Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, QueryList } from "@angular/core";
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { Constants } from "@modules/common/types/Constants";
import { Stitch } from "@modules/common/types/stitch";
import { StitchFilters } from "@modules/common/types/stitch-filters";
import { isNil } from "@modules/common/utils/base";
import { isSameDay } from "@modules/common/utils/date";
import { catchError, filter, map, Observable, of, Subject, switchMap, takeUntil, tap } from "rxjs";

export interface StitchListItem<DATA extends Stitch> {
  data: DATA;
  section: unknown
  showSectionHeader?: boolean;
}

@Component({ template: '' })
export abstract class BaseStitchListComponent<ITEM extends Stitch> implements OnInit, OnDestroy {
  @Output() readonly loadInProgress = new EventEmitter<boolean>();

  public loading = false;
  public loadingError?: unknown;
  public totalCount: number;
  public hasMore: boolean;
  abstract items: StitchListItem<ITEM>[];
  abstract itemElements: QueryList<ElementRef<HTMLElement>>;
  abstract filters: Partial<StitchFilters<ITEM>>;

  public readonly resetSelected = new Subject<void>();
  protected readonly alive: Subject<void> = new Subject();
  protected readonly loadItems = new Subject<{ start: number, end: number, refresh: boolean }>();
  private readonly scrollToPositionSubject = inject(ScrollToPosition, { self: true });

  protected abstract getItems(offset: number, limit: number): Observable<{ items: ITEM[], count: number }>;
  protected abstract getSectionValue(item: ITEM): unknown | undefined;

  ngOnInit(): void {
    this.loadItems
      .pipe(
        tap(() => {
          this.loadInProgress.emit(true);
          this.loadingError = undefined;
        }),
        switchMap(range =>
          this.getItems(range.start, range.end - range.start + 1)
            .pipe(
              map(response => ({ range, response })),
              catchError(err => {
                this.loadInProgress.emit(false);
                this.loadingError = err;
                return of();
              }),
            )
        ),
        filter(data => !!data),
        takeUntil(this.alive),
        tap(() => {
          this.loadInProgress.emit(false);
        })
      )
      .subscribe(({ range, response }) => {
        const converted = this.convertToItems(response.items)
        if (range.refresh) {
          this.items = converted
        } else {
          this.items.splice(range.start, response.items.length, ...converted);
          this.items = this.items.slice(); // new ref required in order to trigger change detection
        }
        this.totalCount = response.count;
        this.hasMore = this.totalCount > this.items.length;
      });

    this.loadInProgress
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((value: boolean) => this.loading = value);

    this.scrollToPositionSubject
      .pipe(
        filter(index => !isNil(index)),
        takeUntil(this.alive)
      )
      .subscribe(index => {
        this.scrollToIndex(index);
      });

    this.resetItems();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  loadMore(size: number = Constants.PAGE_SIZE): void {
    this.loadItems.next({ start: this.items.length, end: this.items.length + size - 1, refresh: false });
  }

  resetItems() {
    this.resetSelected.next();
    this.items = [];
    this.loadItems.next({ start: 0, end: Constants.PAGE_SIZE - 1, refresh: true });
  }

  refreshCurrentItems() {
    this.loadItems.next({ start: 0, end: this.items.length - 1, refresh: true });
  }

  private scrollToIndex(index: number) {
    const el = this.itemElements.get(index);
    el && el.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  private convertToItems(items: ITEM[]): StitchListItem<ITEM>[] {
    return items.reduce((arr : StitchListItem<ITEM>[], item: ITEM) => {
      const converted = this.convertToItem(item);
      converted.showSectionHeader = this.doNeedToShowGroupSeparator(converted.section, arr[arr.length - 1]?.section);
      arr.push(converted);
      return arr;
    }, []);
  }

  private convertToItem(data: ITEM): StitchListItem<ITEM> {
    return {
      data,
      section: this.getSection(data)
    };
  }

  private getSection(item: ITEM): unknown | undefined {
    if (
      item.pinned && this.filters.pinnedOnTop ||
      item.flagged && this.filters.flaggedOnTop ||
      item.snoozed && this.filters.snoozedOnTop ||
      item.followed && this.filters.followedOnTop
    ) {
      return undefined;
    }

    return this.getSectionValue(item)
  }

  private doNeedToShowGroupSeparator(sectionValue: unknown, prevSectionValue?: unknown): boolean {
    if (sectionValue instanceof Date && prevSectionValue instanceof Date) {
      return !isSameDay(sectionValue, prevSectionValue);
    }
    return sectionValue !== prevSectionValue;
  }
}
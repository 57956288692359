// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type Message } from './message';

// Services
import { Application } from '@modules/common/types/application';
import { SortOrder } from '@modules/common/types/sort-order';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import { type SortBy } from '@modules/common/types/SortBy';

export type MessagesListSortField = 'title' | 'date' | 'score';

export class MessagesListState extends BaseStitchListState<Message, MessagesListSortField> {
  @OnChange('onPropertyChange') sortBy?: MessagesListSortField;
  @OnChange('onPropertyChange') today?: boolean;

  constructor(
    { searchService, stateService, stateKey, withAdvanced, defaultSort = { by: 'date', order: SortOrder.DESC } }:
    {
      searchService?: AdvancedSearchService,
      stateService?: StateService,
      stateKey?: StateKey,
      withAdvanced?: boolean,
      defaultSort?: SortBy<MessagesListSortField>
    }
  ) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override applyState(state?: Like<MessagesListState>) {
    super.applyState(state);

    this.sortBy = this.defaultSort.by;
    this.sortOrder = this.defaultSort.order;
    this.today = !!state?.today;
  }

  protected override onPropertyChange(attribute : keyof this) {
    if (
      (attribute === 'from' && this.from) ||
      (attribute === 'to' && this.to)
    ) {
      this.today = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today
    });
  }

  override isDefault(): boolean {
    return (
      super.isDefault() &&
      this.sortBy === this.defaultSort.by &&
      this.sortOrder === this.defaultSort.order &&
      !this.today
    );
  }

  protected override syncFromAS(advancedSearchState: AdvancedSearchState) {
    const state = advancedSearchState[Application.mail]
    this.stitchedWith = state.stitched;
    this.from = state.dateRangeFrom;
    this.to = state.dateRangeTo;
  }

  protected override syncToAS() : void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.mail;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;

    this.searchService.setState(searchState);
  }
}

// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type MessageFolder } from './message-folder';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export class FoldersListState extends BaseStitchListState<MessageFolder> {
  @OnChange('onPropertyChange') sortBy?: 'title' | 'createdAt' | 'updatedAt';

  constructor(
    { searchService, stateService, stateKey, withAdvanced }:
    {
      searchService?: AdvancedSearchService,
      stateService?: StateService,
      stateKey?: StateKey,
      withAdvanced?: boolean
    }
  ) {
    super({ searchService, stateService, stateKey, withAdvanced });
  }

  protected override applyState(state?: Like<FoldersListState>) {
    super.applyState(state);

    this.sortBy = state?.sortBy || 'title';
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy
    });
  }

  isDefault(): boolean {
    return (
      super.isDefault() &&
      this.sortBy === 'title' &&
      this.sortOrder === 'asc'
    );
  }

  protected override syncFromAS({ mail: state }: AdvancedSearchState) {
    this.stitchedWith = state.stitched;
  }

  protected override syncToAS() : void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.mail;
    state.stitched = this.stitchedWith;

    this.searchService.setState(searchState);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-no-content-stub',
    template: `
@if (imageSrc) {
    <img class="messages-stub-image" [src]="imageSrc"/>
}
<p class="messages-stub-text"><ng-content></ng-content></p>`,
    styleUrl: './no-content-stub.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoContentStubComponent {
    @Input() public imageSrc ?: string;
}
import { InjectionToken } from "@angular/core";
import { type StateKey } from "@modules/settings/types/state-key";

export interface MessageFolderListStateServiceConfig {
    stateKey?: StateKey,
    withAdvancedSearch?: boolean
}

export const MessageFolderListStateServiceConfigToken = new InjectionToken<MessageFolderListStateServiceConfig>(
  'Configuration object for message folder list state service'
);
// Common
import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { FoldersListContextMenuComponent } from '../components/folders-list-context-menu/folders-list-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { startWith, takeUntil } from 'rxjs/operators';

// Services
import { MessageFolderListStateService } from '../services/message-folder-list-state-holder.service';

@Directive({
  selector: '[appMessagesFoldersListContextMenu]'
})
export class MessagesFoldersListContextMenuDirective extends ContextMenuDirective implements OnInit {
  @Input() appMessagesFoldersListContextMenuWithAdvancedSearch: boolean;
  @Input() appMessagesFoldersListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  private readonly messageFolderListStateService = inject(MessageFolderListStateService);

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<FoldersListContextMenuComponent>) {
    componentRef.instance.state = this.messageFolderListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appMessagesFoldersListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appMessagesFoldersListContextMenuWithScore;
  }

  registerComponent() {
    return FoldersListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.messageFolderListStateService.state.changes().pipe(
      startWith(null),
      takeUntil(this.alive)
    )
    .subscribe(() => {
      this.isDefaultStateObservable?.next(this.messageFolderListStateService.state.isDefault());
    });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.messageFolderListStateService.state.isDefault()) {
          this.messageFolderListStateService.state.resetToDefault();
        }
      });
  }
}

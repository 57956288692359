// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ContactContextMenuComponent } from '../components/context-menus/contact-context-menu/contact-context-menu.component';

// Types
import { Contact } from '@modules/contacts/types/contact';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appContactContextMenu2]' // TODO remove user-avatar/contact-context-menu
})
export class ContactContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appContactContextMenu2: Contact;
  @Input() appContactContextMenu2Disabled = false;
  @Input() appContactContextMenu2Mode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appContactContextMenu2Opened = this.contextMenuOpened;
  @Output() appContactContextMenu2OpenContact = new EventEmitter;

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appContactContextMenu2Disabled;

    if (this.appContactContextMenu2Mode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return ContactContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ContactContextMenuComponent>) {
    componentRef.instance.contact = this.appContactContextMenu2;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appContactContextMenu2OpenContact.emit();
      });
  }
}

// Common
import {
  Component, ElementRef, EventEmitter, Input, OnChanges,
  OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-multiple-input',
  templateUrl: './autocomplete-multiple-input.component.html',
  styleUrls: ['./autocomplete-multiple-input.component.less'],
})
export class AutocompleteMultipleInputComponent<T> implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() inputFormControl: UntypedFormControl;
  @Input() suggestions?: AutocompleteFactory<T>;
  @Input() valueKey?: keyof T;
  @Input() title: string;
  @Input() placeholder = '';
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() containerStyles: any = {};
  @Input() noBorder = false;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() withClear = false;
  @Input() useInputText = false;
  @Input() useInputTextGetValue?: (value: string) => any;
  @Input() appearance: 'default' | 'amethyst' | 'sapphire' = 'default';
  @Input() invertedColor = false;
  @Input() itemStyles?: any = {};
  @Input() itemTitlePattern?: string;
  @Input() sideOptions?: DropdownSelectItem<unknown, T>[];
  @Input() sideValue?: string;
  @Input() optionsDraggable = false;

  // Outputs
  @Output() select = new EventEmitter<DropdownSelectItem<unknown, T>>();

  // Public
  public innerControl = new UntypedFormControl();
  public options: DropdownSelectItem<unknown, T>[] = [];
  public readonly popoverCustomTrigger = new Subject<void>();
  public readonly popoverHide = new Subject<void>();
  public selectedItems: DropdownSelectItem<unknown, T>[] = [];
  public focused: boolean;
  public loading = false;
  public selectedSideOption: DropdownSelectItem<unknown, T>;
  public sidePopoverHide = new Subject<void>();
  public autocompleteClicked: boolean;

  // Private
  private readonly alive: Subject<void> = new Subject();
  private readonly suggestionsChanged = new Subject<void>();
  private readonly inputControlChanged = new Subject<void>();
  private readonly loadMissingItems = new Subject<any>();
  private readonly sideOption = new BehaviorSubject<DropdownSelectItem<unknown, T>>(null);
  private readonly cachedItems: Map<T[typeof this.valueKey], DropdownSelectItem<unknown, T>> = new Map();

  // View Children
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestionsChanged
      .pipe(
        switchMap(() => combineLatest([
          this.innerControl.valueChanges.pipe(startWith(<string>null)),
          this.sideOption.pipe(startWith(this.sideOption.value))
        ])),
        filter(() => !!this.suggestions),
        tap(() => { this.loading = true; }),
        debounceTime(600),
        switchMap(([title, sideValue]) => this.suggestions(
          title,
          null,
          { except: this.selectedItems.map(i => i.value), sideValue: sideValue?.value }
        )),
        takeUntil(this.alive)
      )
      .subscribe(options => {
        this.loading = false;
        this.options = options;
        if (this.focused && this.innerControl.value) {
          this.showPopover();
        }
      });

    this.suggestionsChanged.next();

    this.innerControl.valueChanges
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe(value => {
        if (!value) {
          this.hidePopover();
        }
      });

    this.loadMissingItems
      .pipe(
        filter(() => !!this.suggestions),
        switchMap(missingItems => this.suggestions(undefined, missingItems)),
        takeUntil(this.alive),
        filter(missingItems => missingItems.length > 0)
      )
      .subscribe(missingItems => {
        for (const item of missingItems) {
          this.cachedItems.set(this.getValueField(item.value), item);
        }
        this.inputControlChanged.next();
      });

    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl.valueChanges.pipe(startWith(this.inputFormControl.value))),
        map(values => Array.isArray(values) ? values : []),
        tap(values => {
          const missingItems = values.filter(value => !this.cachedItems.has(this.getValueField(value)));
          if (missingItems.length) {
            this.loadMissingItems.next(missingItems);
          }
        }),
        takeUntil(this.alive)
      )
      .subscribe(values => {
        this.selectedItems = values.map(value => this.cachedItems.get(this.getValueField(value)))
                                   .filter(item => !!item);
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('valueKey' in changes) {
      this.cachedItems.clear();
    }

    if ('suggestions' in changes) {
      this.suggestionsChanged.next();
    }

    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }

    if (('sideOptions' in changes || 'sideValue' in changes) && this.sideOptions) {
      this.selectedSideOption = this.sideValue
        ? this.sideOptions.find(option => option.value === this.sideValue)
        : this.sideOptions[0];

      this.sideOption.next(this.selectedSideOption);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFocus() {
    this.focused = true;
    this.autocompleteClicked = false;

    if (this.innerControl.value) {
      this.showPopover();
    }
  }

  handleBlur() {
    this.focused = false;
    if (!this.autocompleteClicked) {
      this.handleUseInputText(false);
      this.autocompleteClicked = false;
    }
  }

  handleClick() {
    if (this.innerControl.value) {
      this.showPopover();
    }
  }

  handleSelect(option: DropdownSelectItem<unknown, T>, focusAfterSelect = true) {
    if (!this.inputFormControl) { return; }

    const currentValue = this.inputFormControl.value || [];

    if (currentValue.some(item => this.compare(item, option.value))) { return; }

    this.innerControl.setValue('');

    this.cachedItems.set(this.getValueField(option.value), option);

    this.inputFormControl.setValue([...currentValue, option.value ]);
    this.inputFormControl.markAsDirty();

    this.select.emit(option);

    focusAfterSelect && this.inputElement.nativeElement.focus();
    this.hidePopover();
  }

  handleSideSelect(option: DropdownSelectItem<unknown, T>) {
    this.selectedSideOption = option;
    this.sideOption.next(option);
    this.sidePopoverHide.next();
  }

  handleClear() {
    this.inputFormControl.setValue([]);
    this.inputFormControl.markAsDirty();
  }

  handleDropped(item: DropdownSelectItem<unknown, T>) {
    this.inputFormControl.setValue(this.inputFormControl.value.filter(value => !this.compare(value, item?.value)));
    this.inputFormControl.markAsDirty();
  }

  removeItem(item: DropdownSelectItem<unknown, T>) {
    if (!this.inputFormControl) { return; }

    this.inputFormControl.setValue(this.inputFormControl.value.filter(value => !this.compare(value, item.value)));
    this.inputFormControl.markAsDirty();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter' || event.code === 'Comma') {
      event.stopPropagation();
      event.preventDefault(); // prevent form submit

      this.handleUseInputText();
    }
  }

  onAutocompleteClick() {
    this.autocompleteClicked = true;
  }

  private compare(value1 : T, value2: T) : boolean {
    if (this.valueKey) {
      return this.getValueField(value1) === this.getValueField(value2);
    } else {
      return value1 === value2;
    }
  }

  private getValueField(value: T) : T[typeof this.valueKey] {
    return this.valueKey ? value?.[this.valueKey] : value as T[typeof this.valueKey];
  }

  private handleUseInputText(focusAfterSelect = true) {
    if (!this.useInputText) { return; }
    if (!this.innerControl.value?.trim()?.length) { return; }

    this.handleSelect(
      this.useInputTextGetValue
        ? this.useInputTextGetValue(this.innerControl.value)
        : { title: this.innerControl.value, value: this.innerControl.value },
      focusAfterSelect
    );
  }

  private showPopover() {
    this.popoverCustomTrigger.next();
  }

  private hidePopover() {
    this.popoverHide.next();
  }
}

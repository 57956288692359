// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { isEqual } from 'lodash';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ManageContextMenuComponent } from '../components/manage-context-menu/manage-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { ManageListState } from '../types/manage-list-state';
import { StateKey } from '@modules/settings/types/state-key';

// RX
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appKnotsManageContextMenu]'
})
export class ManageContextMenuDirective extends ContextMenuDirective implements OnInit, OnChanges {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomRight';
  @Input() appKnotsManageContextMenu: boolean;
  @Input() appKnotsManageContextMenuStateKey: StateKey;
  @Input() appKnotsManageContextMenuNeighbours: boolean;
  @Input() appKnotsManageContextMenuWithTemporalExpressions = true;

  // Outputs
  @Output() appKnotsManageContextMenuStateChange = new EventEmitter<ManageListState>();

  // Private
  private defaultState: ManageListState = {
    sort: {
      by: 'name',
      order: 'asc'
    },
    filters: {
      createdFrom: null,
      createdTo: null,
      scoreFrom: 0.0003,
      scoreTo: 0.1
    }
  };
  private state: ManageListState = { ...this.defaultState };

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<ManageContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.global = this.appKnotsManageContextMenu;
    componentRef.instance.neighbours = this.appKnotsManageContextMenuNeighbours;
    componentRef.instance.withTemporalExpressions = this.appKnotsManageContextMenuWithTemporalExpressions;

    componentRef.instance.stateChange
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(state => {
        this.setState(state);
        componentRef.instance.state = this.state;

        this.appKnotsManageContextMenuStateChange.emit(this.state);

        if (this.appKnotsManageContextMenuStateKey) {
          this.stateService.setState(this.appKnotsManageContextMenuStateKey, this.state);
        }
      });
  }

  registerComponent() {
    return ManageContextMenuComponent;
  }

  /**
   * Lifecycle
   */

   override ngOnInit() {
    super.ngOnInit();
    this.stateService.getState(this.appKnotsManageContextMenuStateKey)
      .pipe(
        map(state => Object.assign({}, this.state, state)),
        map((state: any) => ({
          ...(state || {}),
          filters: {
            ...(state?.filters || {}),
            createdFrom: state?.filters?.createdFrom ? new Date(state.filters.createdFrom) : null,
            createdTo: state?.filters?.createdTo ? new Date(state.filters.createdTo) : null,
            scoreFrom: state?.filters?.scoreFrom || 0,
            scoreTo: state?.filters?.scoreTo || 50
          }
        })),
        take(1),
        takeUntil(this.alive)
      )
      .subscribe((state: ManageListState) => {
        this.setState(state);
        this.appKnotsManageContextMenuStateChange.emit(this.state);
      });

    this.isDefaultStateObservable
      ?.pipe(takeUntil(this.alive))
      ?.subscribe(isDefaultState => {
        if (isDefaultState && !isEqual(this.defaultState, this.state)) {
          this.state = this.defaultState;
          this.appKnotsManageContextMenuStateChange.emit(this.defaultState);
          this.stateService.setState(this.appKnotsManageContextMenuStateKey, this.defaultState);
        }
      });
  }

  /**
   * Actions
   */

  setState(newState: ManageListState) {
    this.isDefaultStateObservable?.next(isEqual(this.defaultState, newState));

    this.state = newState;
  }
}

// Common
import { Injector } from '@angular/core';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';
import { LocalStorageItem, LSString } from 'src/app/decorators/local-storage.decorator';
import { appToEntityTypes } from '../utils/stitch';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Team } from '@modules/settings/types/team';
import { Application } from '../types/application';

// RX
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, retry, startWith, switchMap, tap } from 'rxjs/operators';

// Services
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { GlobalStateService } from './global-state.service';

export abstract class BaseAppStateService<C extends Stitch, I extends Stitch, VirtualFolder> {
  private refreshAll = new Subject<void>();
  private mainView = new BehaviorSubject<C | I>(null);
  private globalStateService: GlobalStateService;
  private spaceId: BehaviorSubject<string>;
  @LSString({ lsKey: 'virtual-folder' }) private virtualFolder: LocalStorageItem<VirtualFolder>;
  @LSString({ lsKey: 'team-id' }) private teamId: LocalStorageItem<string>;

  protected abstract application: Application;
  protected abstract defaultVirtualFolder: VirtualFolder;
  protected stitchServiceFactory: StitchServiceFactory;

  constructor(
    injector: Injector,
  ) {
    this.stitchServiceFactory = injector.get(StitchServiceFactory);
    this.globalStateService = injector.get(GlobalStateService);
    this.spaceId = injector.get(SPACE_ID);
  }

  abstract getTabs(): Observable<unknown>;

  getRefreshAll(): Observable<void> {
    return this.refreshAll.asObservable();
  }

  forceRefresh(): void {
    this.refreshAll.next();
  }

  getMainView(): Observable<C | I> {
    return this.spaceId
      .pipe(
        tap(() => this.mainView.next(null)),
        switchMap(() => merge(
          this.mainView,
          this.globalStateService.getFullView(appToEntityTypes[this.application])
        )),
        startWith(null as Stitch),
        switchMap((item) => {
          if (!item) { return of(null as C); }
          if (!item.id) { return of(item as C); }

          return this.stitchServiceFactory.getServiceByStitchType(item.getStitchType()).getItem(item.id).pipe(
            catchError(err => {
              console.error(err);
              return of();
            })
          )
        })
      ) as Observable<C | I>
  }

  setMainView(item: C | I) {
    this.mainView.next(item);
  }

  getVirtualFolder() {
    return this.virtualFolder.get(this.application);
  }

  setVirtualFolder(sidebar: VirtualFolder) {
    return this.virtualFolder.set(sidebar, this.application);
  }

  setDefaultVirtualFolder() {
    return this.virtualFolder.set(this.defaultVirtualFolder, this.application);
  }

  getTeam() {
    return this.teamId.get(this.application)
      .pipe(map(id => id || null));
  }

  setTeam(team: Team) {
    return this.teamId.set(team?.id, this.application);
  }
}

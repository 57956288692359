// Types
import { Application } from '@modules/common/types/application';
import { Like } from '@modules/common/types/like';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Priority } from '@modules/tasks/types/priority';
import { TasksListState } from './tasks-list-state';
import { VirtualFolder } from './virtual-folder';

// Utils
import { isNil } from '@modules/common/utils/base';
import { addDays, beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Pipes
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';
import { Task } from './task';

export class TasksFilters extends StitchChildFilters<Task> {
  // Filters
  priority?: Priority;
  withPriority?: boolean;
  fromTime?: Date;
  toTime?: Date;
  startDate?: Date;
  dueDate?: Date;
  scheduled?: boolean;
  overdue?: boolean;
  completed?: boolean;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'priority' | 'position' | 'score' | 'issue-key' | 'sharedViaLinkAt';

  constructor (filters?: Like<TasksFilters>) {
    super(filters);
    this.completed = filters?.completed;
    this.dueDate = filters?.dueDate;
    this.fromTime = filters?.fromTime;
    this.overdue = filters?.overdue;
    this.priority = filters?.priority;
    this.scheduled = filters?.scheduled;
    this.sortBy = filters?.sortBy;
    this.startDate = filters?.startDate;
    this.toTime = filters?.toTime;
    this.withPriority = filters?.withPriority;
  }

  override applyListState(state: TasksListState) : this {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    if (state?.next7days) {
      const today = new Date();
      this.fromTime = beginningOfDay(addDays(today, 1));
      this.toTime = endOfDay(addDays(today, 7));
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['projects_ids[]'] = this.containersIds; }
    if (this.originsIds) { result['projects_ids[]'] = this.originsIds; }

    if (!isNil(this.noContainer)) { result['no_project'] = this.noContainer + ''; }

    if (this.priority) { result['priority'] = this.priority; }
    if (!isNil(this.withPriority)) { result['with_priority'] = this.withPriority + ''; }

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }
    if (!isNil(this.startDate)) { result['startDate'] = beginningOfDay(this.startDate).toISOString(); }
    if (!isNil(this.dueDate)) { result['dueDate'] = endOfDay(this.dueDate).toISOString(); }
    if (!isNil(this.scheduled)) { result['scheduled'] = this.scheduled + ''; }

    if (this.overdue) { result['overdue_till'] = new Date().toISOString(); }
    if (this.completed) { result['completed'] = this.completed + ''; }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.tasking);

    const state = advanced.tasking;

    this.esTitle = state.title;
    this.esBody = state.description;
    this.overdue = state.overdue;
    this.completed = state.completed;
    this.fromTime = state.dateRangeFrom;
    this.toTime = state.dateRangeTo;
    this.startDate = state.startDate;
    this.dueDate = state.dueDate;
    this.priority = state.priority;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    const today = new Date();

    this.deleted = false;
    this.archived = false;
    this.noParent = null;
    this.noContainer = null;
    this.snoozedOnTop = true;
    this.followedOnTop = true;
 
    switch (folder) {
      case 'archived':
        this.archived = true;
        break;
      case 'deleted':
        this.deleted = true;
        break;
      case 'today':
        this.fromTime = beginningOfDay(today);
        this.toTime = endOfDay(today);
        break;
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(folder);

        this.fromTime = beginningOfDay(day);
        this.toTime = endOfDay(day);
        break;
      case 'week':
        this.fromTime = beginningOfDay(addDays(today, 1));
        this.toTime = endOfDay(addDays(today, 7));
        break;
      case 'all_tasks':
        this.noParent = true;
        this.noContainer = true;
        break;
      case 'all_projects':
        break;
      case 'overdue':
        this.overdue = true;
        break;
      case 'priority':
        this.withPriority = true;
        break;
      case 'scheduled':
        this.scheduled = true;
        break;
      case 'unscheduled':
        this.scheduled = false;
        break;
      case 'low':
      case 'normal':
      case 'high':
        this.priority = folder;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}

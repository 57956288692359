// Common
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

// RxJS
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { Constants } from '@modules/common/types/Constants';
import { KnotSource } from '@modules/knots/types/knot-source';
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';

// Env
import { environment } from '@environment';

// Components
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import { MessageFolderComponent } from '@modules/elements/components/message-folder/message-folder.component';

@Component({
  selector: 'app-message-folders-list',
  templateUrl: './folders-list.component.html',
  styleUrls: ['./folders-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class MessageFoldersListComponent extends BaseStitchListComponent<MessageFolder> implements OnChanges {
  @Input() override filters: Partial<FoldersFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  @Output() readonly clickFolder = new EventEmitter<MessageFolder>();
  @Output() readonly openFolder = new EventEmitter<MessageFolder>();
  @Output() readonly openMessage = new EventEmitter<Message>();
  @Output() readonly doubleClickFolder = new EventEmitter<MessageFolder>();

  @ViewChildren(MessageFolderComponent, { read: ElementRef<HTMLElement> })
  override itemElements: QueryList<ElementRef<HTMLElement>>;

  items: StitchListItem<MessageFolder>[] = [];

  public readonly Constants = Constants;

  constructor(
    private foldersService: MessageFoldersService,
    private messagesStateService: MailAppStateService,
    private linkedInfoService: LinkedInfoService
  ) {
    super();

    merge(
      this.messagesStateService.getRefreshAll(),
      this.linkedInfoService.getRefresh(),
      this.foldersService.getRefresh(),
      interval(environment.messageFetchInterval)
    ).pipe(
      takeUntil(this.alive)
    ).subscribe(() => {
      this.refreshCurrentItems();
    });

    merge(
      this.messagesStateService.getRefreshAll(),
      this.foldersService.getRefresh(),
    ).pipe(
      takeUntil(this.alive)
    ).subscribe(() => {
      this.resetSelected.next();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes && !changes.filters.isFirstChange()) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: MessageFolder[], count: number }> {
    return this.foldersService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  handleDoubleClickItem(folder: MessageFolder) {
    this.doubleClickFolder.emit(folder);
  }

  handleClickItem(folder: MessageFolder) {
    this.clickFolder.emit(folder);
  }

  protected override getSectionValue(folder: MessageFolder): unknown | undefined {
    if (this.filters.sortBy === 'createdAt' || this.filters.sortBy === 'updatedAt') {
      return folder[this.filters.sortBy]
    }
    return undefined;
  }
}

<div class="bottom-bar">
  @if (showChild) {
    <div
      class="bottom-bar-item"
      [class.expanded]="childExpanded"
      (click)="onExpandclicked($event)"
    >
      <mat-icon
        class="bottom-bar-item-icon"
        [svgIcon]="childIconByStitchType[stitchItem | stitchType]"
      />
      <span>{{ childCount }}</span>
      <mat-icon
        class="bottom-bar-item-icon chevron"
        svgIcon="sapphire-chevron-down"
      />
    </div>
  }

  @if (showStitch) {
    <div
      class="bottom-bar-item"
      [class.expanded]="stitchExpanded"
      [appStitchInfoPopover]="stitchItem"
      stchPopoverPlacement="bottom"
      (appStitchInfoPopoverOpened)="stitchExpanded = $event; expand.emit($event)"
    >
      <mat-icon
        class="bottom-bar-item-icon"
        svgIcon="sapphire-stitch"
      />
      <span>{{ stitchCount }}</span>
      <mat-icon
        class="bottom-bar-item-icon chevron"
        svgIcon="sapphire-chevron-down"
      />
    </div>
  }

  @if (showNested) {
    <div
      class="bottom-bar-item"
      [class.expanded]="nestedExpanded"
    >
      <mat-icon
        class="bottom-bar-item-icon"
        svgIcon="sapphire-subsidiary"
      />
      <span>{{ nestedCount }}</span>
      <mat-icon
        class="bottom-bar-item-icon chevron"
        svgIcon="sapphire-chevron-down"
      />
    </div>
  }
</div>

// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { FileContextMenuComponent } from '../components/context-menus/file-context-menu/file-context-menu.component';

// Types
import { File } from '@modules/files/types/file';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appFileContextMenu]'
})
export class FileContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appFileContextMenu: File;
  @Input() appFileContextMenuDisabled = false;
  @Input() appFileContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appFileContextMenuOpened = this.contextMenuOpened;
  @Output() appFileContextMenuOpenFile = new EventEmitter;

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appFileContextMenuDisabled;

    if (this.appFileContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return FileContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<FileContextMenuComponent>) {
    componentRef.instance.file = this.appFileContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appFileContextMenuOpenFile.emit();
      });
  }
}

// Common
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// Types
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { KnotSortableField } from '@modules/knots/types/knot-sortable-field';
import { KnotSource } from '@modules/knots/types/knot-source';

// RX
import { combineLatest, Subject } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-knots-manage-context-menu',
  templateUrl: './manage-context-menu.component.html',
  styleUrls: ['./manage-context-menu.component.less']
})
export class ManageContextMenuComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() state: ManageListState;
  @Input() global: boolean;
  @Input() neighbours: boolean;
  @Input() withTemporalExpressions = true;

  // Outputs
  @Output() stateChange = new EventEmitter<ManageListState>();

  // Public
  public innerFromDate = new UntypedFormControl();
  public innerToDate = new UntypedFormControl();
  public scoreFrom = new UntypedFormControl();
  public scoreTo = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public sortOptions = [
    { title: 'By Date', key: 'date' },
    { title: 'Alphabetically', key: 'name' },
    { title: 'Score', key: 'score' },
    { title: 'Most Frequent', key: 'frequency' },
    { title: 'Most Recent', key: 'recency' }
  ];
  public sources = [
    { key: 'user', title: 'User' },
    { key: 'deepentropy', title: 'Deepentropy' },
    { key: 'cesar_named_entities', title: 'Cesar (named entities)' },
    { key: 'cesar_textrank_scored', title: 'Cesar (bm25 scored)' },
    { key: 'cesar_tfidf_scored', title: 'Cesar (tfidf scored)' },
    { key: 'cesar_perturbation_scored_phrases', title: 'Cesar (perturbation scored)'}
  ];

  private alive = new Subject<void>();

  constructor (
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (this.state.filters.createdFrom) {
      this.innerFromDate.setValue(this.state.filters.createdFrom);
    }

    if (this.state.filters.createdTo) {
      this.innerToDate.setValue(this.state.filters.createdTo);
    }

    if (this.state.filters.scoreFrom) {
      this.scoreFrom.setValue(this.state.filters.scoreFrom);
    }

    if (this.state.filters.scoreTo) {
      this.scoreTo.setValue(this.state.filters.scoreTo);
    }

    combineLatest([
      this.innerFromDate.valueChanges.pipe(startWith(this.state.filters.createdFrom)),
      this.innerToDate.valueChanges.pipe(startWith(this.state.filters.createdTo)),
      this.scoreFrom.valueChanges.pipe(startWith(this.state.filters.scoreFrom)),
      this.scoreTo.valueChanges.pipe(startWith(this.state.filters.scoreTo))
    ])
      .pipe(
        skip(1),
        takeUntil(this.alive)
      )
      .subscribe(([createdFrom, creratedTo, scoreFrom, scoreTo]) => {
        this.stateChange.emit({
          ...this.state,
          filters: {
            ...this.state.filters,
            createdFrom: createdFrom && beginningOfDay(createdFrom),
            createdTo: creratedTo && endOfDay(creratedTo),
            scoreFrom,
            scoreTo
          }
        });
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  sort(sortBy: KnotSortableField) {
    let { by, order, secondaryBy, secondaryOrder } = this.state.sort;

    if (by === sortBy) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      secondaryBy = by;
      secondaryOrder = order;
      by = sortBy;
      order = 'asc';
    }

    this.stateChange.emit({
      ...this.state,
      sort: { by, order, secondaryBy, secondaryOrder }
    });
  }

  setBooleanFilter(property: 'collapsed' | 'autodiscovered' | 'commonNeighbours' | 'threeInARow') {
    this.stateChange.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        [property]: !this.state.filters[property]
      }
    });
  }

  meetingIntentML() {
    this.stateChange.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        meetingIntentML: !this.state?.filters?.meetingIntentML,
        meetingIntentRule: false
      }
    });
  }

  meetingIntentRule() {
    this.stateChange.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        meetingIntentRule: !this.state?.filters?.meetingIntentRule,
        meetingIntentML: false
      }
    });
  }

  handleClose() {
    this.closePopoverToken.next();
  }

  setSource(source: KnotSource) {
    this.stateChange.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        source
      }
    });
  }
}

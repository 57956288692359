// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Components
import { DnDNestedItemsComponent } from '../drag-n-drop/components/dnd-nested-items/dnd-nested-items.component';
import { DraggableElementComponent } from './components/draggable-element/draggable-element.component';
import { OrderedItemComponent } from './components/ordered-item/ordered-item.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';
import { UtilsModule } from '@modules/utils/utils.module';

// Directives
import { DnDNestedForDirective } from '../drag-n-drop/directives/dnd-nested-for.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { DroppableDirective } from './directives/droppable.directive';
import { ForOrderedItemsDirective } from './directives/app-draggable-list-for.directive';
import { SelectableItemDirective } from './directives/selectable.directive';
import { SelectableListDirective } from './directives/selectable-list.directive';

// Services
import { DragNDropService } from './services/drag-n-drop.service';

// Pipes
import { OrderedItemZonePositionPipe } from './pipes/ordered-item-zone-position.pipe';
import { OrderedItemPlaceholderSizePipe } from './pipes/ordered-item-placeholder-size.pipe';

@NgModule({
  exports: [
    DnDNestedForDirective,
    DraggableDirective,
    DroppableDirective,
    ForOrderedItemsDirective,
    SelectableItemDirective,
    SelectableListDirective,
  ],
  declarations: [
    // Components
    DraggableElementComponent,
    OrderedItemComponent,
    DnDNestedItemsComponent,
    // Directives
    DnDNestedForDirective,
    DraggableDirective,
    DroppableDirective,
    ForOrderedItemsDirective,
    // Pipes
    OrderedItemPlaceholderSizePipe,
    OrderedItemZonePositionPipe,
  ],
  imports: [
    AngularCommonModule,
    OverlayModule,
    UtilsModule,
    SelectableItemDirective,
    SelectableListDirective
  ],
  providers: [
    DragNDropService, // This is provided globally because we expect enabling DnD between spaces in future
    OrderedItemPlaceholderSizePipe,
    OrderedItemZonePositionPipe,
  ]
})
export class DragNDropModule {}

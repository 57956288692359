// Types
import { Filters } from '@modules/common/types/filters';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { ManageListState } from './manage-list-state';
import { SortOrder } from '@modules/common/types/sort-order';
import { KnotSortableField } from './knot-sortable-field';
import { KnotSource } from './knot-source';
import { Tag } from '@modules/tags/types/tag';
import { Connection } from '@modules/connections/types/connection';

// Utils
import { isNil } from '@modules/common/utils/base';

export class KnotFilters extends Filters {
  // Filters
  autodiscovered?: boolean;
  collapsed?: boolean;
  commonNeighbours?: boolean;
  connections?: Connection[];
  createdFrom?: Date;
  createdTo?: Date;
  ids?: string[];
  items?: Stitch[];
  names?: string[];
  pinned?: boolean;
  query?: string;
  scoreFrom?: number;
  scoreTo?: number;
  source?: KnotSource;
  tags?: Tag[];
  threeInARow?: boolean;
  withUnread?: boolean;

  // Sort Options
  sortBy?: KnotSortableField;
  pinnedOnTop?: boolean;
  secondarySortBy?: KnotSortableField;
  secondarySortOrder?: SortOrder;

  constructor(filters: Partial<KnotFilters> = {}) {
    super(filters);
    this.autodiscovered = filters.autodiscovered;
    this.collapsed = filters.collapsed;
    this.commonNeighbours = filters.commonNeighbours;
    this.connections = filters.connections;
    this.createdFrom = filters.createdFrom;
    this.createdTo = filters.createdTo;
    this.items = filters.items;
    this.names = filters.names;
    this.pinned = filters.pinned;
    this.pinnedOnTop = filters.pinnedOnTop;
    this.query = filters.query;
    this.scoreFrom = filters.scoreFrom;
    this.scoreTo = filters.scoreTo;
    this.secondarySortBy = filters.secondarySortBy;
    this.secondarySortOrder = filters.secondarySortOrder;
    this.sortBy = filters.sortBy;
    this.source = filters.source;
    this.tags = filters.tags;
    this.threeInARow = filters.threeInARow;
    this.withUnread = filters.withUnread;
  }

  static fromManageListState(state: ManageListState): KnotFilters {
    return new KnotFilters({
      autodiscovered: state.filters.autodiscovered,
      collapsed: state.filters.collapsed,
      commonNeighbours: state.filters.commonNeighbours,
      createdFrom: state.filters.createdFrom,
      createdTo: state.filters.createdTo,
      scoreFrom: state.filters.scoreFrom,
      scoreTo: state.filters.scoreTo,
      secondarySortBy: state.sort.secondaryBy,
      secondarySortOrder: state.sort.secondaryOrder,
      sortBy: state.sort.by,
      sortOrder: state.sort.order,
      source: state.filters.source,
      threeInARow: state.filters.threeInARow,
    });
  }

  format(): { [param: string]: string | string[]; } {
    const result = {};

    if (!isNil(this.pinned)) {
      result['pinned'] = this.pinned + '';
    }

    if (!isNil(this.withUnread)) { result['with_unread'] = this.withUnread + ''; }

    if (this.names?.length) { result['names[]'] = this.names; }
    if (this.tags?.length) { result['tags[]'] = this.tags.map(({ name }) => name); }
    if (this.connections?.length) { result['connections[]'] = this.connections.map(({ name }) => name); }

    if (this.autodiscovered) { result['auto_discovery'] = true };
    if (this.source) { result['source'] = this.source };
    if (this.query?.length) { result['query'] = this.query };
    if (this.ids?.length) { result['ids[]'] = this.ids };
    if (this.collapsed) { result['collapsed'] = this.collapsed };

    if (this.createdFrom) { result['created_from_time'] = this.createdFrom.toISOString(); }
    if (this.createdTo) { result['created_to_time'] = this.createdTo.toISOString(); }

    if (this.scoreFrom) { result['score_from'] = this.scoreFrom; }
    if (this.scoreTo) { result['score_to'] = this.scoreTo; }

    if (this.threeInARow) { result['three_in_a_row'] = true; }
    if (this.commonNeighbours) { result['common_neighbours'] = true; }

    [
      ['message_folders_ids[]', StitchType.messageFolder],
      ['messages_ids[]', StitchType.message],
      ['calendars_ids[]', StitchType.calendar],
      ['events_ids[]', StitchType.event],
      ['projects_ids[]', StitchType.project],
      ['tasks_ids[]', StitchType.task],
      ['notebooks_ids[]', StitchType.notebook],
      ['notes_ids[]', StitchType.note],
      ['groups_ids[]', StitchType.group],
      ['contacts_ids[]', StitchType.contact],
      ['folders_ids[]', StitchType.folder],
      ['files_ids[]', StitchType.file],
    ]
      .forEach(([key, stitchType]) => {
        const items = this.items?.filter(item => item?.getStitchType() === stitchType) || [];

        if (items.length) {
          result[key] = items.map(item => item.id);
        }
      });

    if (this.sortBy) { result['sort_by'] = this.sortBy; }
    if (this.sortOrder) { result['sort_order'] = this.sortOrder; }
    if (this.secondarySortBy) { result['secondary_sort_by'] = this.secondarySortBy; }
    if (this.secondarySortOrder) { result['secondary_sort_order'] = this.secondarySortOrder; }

    if (this.pinnedOnTop) { result['pinned_on_top'] = true; }

    if (!isNil(this.limit)) { result['limit'] = this.limit + ''; }
    if (!isNil(this.offset)) { result['offset'] = this.offset + ''; }

    return result;
  }
}

// Common
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// Utils
import { isNil } from '@modules/common/utils/base';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Application } from '@modules/common/types/application';
import { MessagesListState } from './messages-list-state';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Like } from '@modules/common/types/like';
import { Participant } from '@modules/messages/types/participant';
import { VirtualFolder } from './virtual-folder';
import { Message } from './message';

export class MessagesFilters extends StitchChildFilters<Message> {
  // Filters
  bulk?: boolean;
  draft?: boolean;
  followed?: boolean;
  fromTime?: Date;
  scheduled?: boolean;
  sent?: boolean;
  snoozed?: boolean;
  spam?: boolean;
  threadsIds?: string[];
  toTime?: Date;

  // Advanced filters
  bcc?: Pick<Participant, 'name' | 'address'>[];
  cc?: Pick<Participant, 'name' | 'address'>[];
  dateReceived?: Date;
  dateSent?: Date;
  hasAttachments?: boolean;
  recipients?: Pick<Participant, 'name' | 'address'>[];
  senders?: Pick<Participant, 'name' | 'address'>[];
  size?: { from?: number, to?: number };

  // Sort Options
  sortBy?: 'title' | 'date' | 'score' | 'sharedViaLinkAt';

  // Other
  groupBy?: string;

  constructor(filters?: Like<MessagesFilters>) {
    super(filters);
    this.bulk = filters?.bulk;
    this.dateReceived = filters?.dateReceived;
    this.dateSent = filters?.dateSent;
    this.draft = filters?.draft;
    this.followed = filters?.followed;
    this.fromTime = filters?.fromTime;
    this.groupBy = filters?.groupBy;
    this.hasAttachments = filters?.hasAttachments;
    this.recipients = filters?.recipients;
    this.cc = filters?.cc;
    this.bcc = filters?.bcc;
    this.scheduled = filters?.scheduled;
    this.senders = filters?.senders;
    this.sent = filters?.sent;
    this.size = filters?.size;
    this.snoozed = filters?.snoozed;
    this.sortBy = filters?.sortBy;
    this.spam = filters?.spam;
    this.threadsIds = filters?.threadsIds;
    this.toTime = filters?.toTime;
  }

  applyListState(state: MessagesListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds?.length > 0) { result['folders_ids[]'] = this.containersIds; }
    if (this.threadsIds) { result['threads_ids[]'] = this.threadsIds; }

    if (this.fromTime) { result['from_time'] = beginningOfDay(this.fromTime).toISOString(); }
    if (this.toTime) { result['to_time'] = beginningOfDay(this.toTime).toISOString(); }

    if (!isNil(this.snoozed)) { result['snoozed'] = this.snoozed + ''; }
    if (!isNil(this.followed)) { result['followed'] = this.followed + ''; }
    if (!isNil(this.scheduled)) { result['scheduled'] = this.scheduled + ''; }
    if (!isNil(this.bulk)) { result['bulk'] = this.bulk + ''; }
    if (!isNil(this.sent)) { result['sent'] = this.sent + ''; }
    if (!isNil(this.spam)) { result['spam'] = this.spam + ''; }
    if (!isNil(this.draft)) { result['draft'] = this.draft + ''; }
    if (!isNil(this.dateReceived)) { result['date_received'] = beginningOfDay(this.dateReceived).toISOString(); }
    if (!isNil(this.dateSent)) { result['date_sent'] = beginningOfDay(this.dateSent).toISOString(); }
    if (!isNil(this.senders)) { result['senders[]'] = this.senders.map(({ address }) => address); }
    if (!isNil(this.recipients)) { result['recipients[]'] = this.recipients.map(({ address }) => address); }
    if (!isNil(this.cc)) { result['cc[]'] = this.cc.map(({ address }) => address); }
    if (!isNil(this.bcc)) { result['bcc[]'] = this.bcc.map(({ address }) => address); }
    if (this.hasAttachments) { result['has_attachments'] = this.hasAttachments + ''; }
    if (!isNil(this.size?.from)) { result['size_from'] = this.size.from; }
    if (!isNil(this.size?.to)) { result['size_to'] = this.size.to; }
    if (!isNil(this.noContainer)) { result['no_folder'] = this.noContainer + ''; }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.mail);

    const state = advanced.mail;

    this.bcc = state.bcc;
    this.cc = state.cc;
    this.dateReceived = state.dateReceived;
    this.dateSent = state.dateSent;
    this.esBody = state.body;
    this.esTitle = state.subject;
    this.fromTime = state.dateRangeFrom;
    this.hasAttachments = state.hasAttachments;
    this.recipients = state.to;
    this.senders = state.from;
    this.size = state.size;
    this.toTime = state.dateRangeTo;

    return this;
  }

  applyVirtualFolder(sidebar: VirtualFolder) {
    if (!sidebar) { return this; }

    this.deleted = false;
    this.archived = false;
    this.snoozedOnTop = false;
    this.followedOnTop = false;
    this.noContainer = null;

    switch (sidebar) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'all_messages':
        this.spam = false;
        this.draft = false;
        this.snoozedOnTop = true;
        this.followedOnTop = true;
        this.bulk = false;
        this.sent = false;
        break;
      case 'bulk':
        this.spam = false;
        this.bulk = true;
        this.draft = false;
        this.sent = false;
        break;
      case 'all_folders':
        this.snoozedOnTop = true;
        this.followedOnTop = true;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'scheduled':
        this.scheduled = true;
        this.draft = false;
        break;
      case 'sent':
        this.sent = true;
        this.draft = false;
        this.scheduled = false;
        break;
      case 'spam':
        this.spam = true;
        this.sent = false;
        break;
      case 'draft':
        this.draft = true;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      default:
        checkExhaustiveness(sidebar);
    }

    return this;
  }
}

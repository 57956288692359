// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environment';
import { LSItem, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

// RX
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';

// Types
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { AdvancedSearchState } from '../types/advanced-search-state';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';

@Injectable()
export class AdvancedSearchService {

  @LSItem({ lsKey: 'as' }) private store: LocalStorageItem<AdvancedSearchState>;
  private readonly state = new BehaviorSubject<AdvancedSearchState>(null);
  private variant: string;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {}

  init(variant: string) {
    this.variant = variant;
    this.state.next(new AdvancedSearchState(this.store.getSync(this.variant)));
  }

  addKnowledgeItem(item: Knot | Tag | Connection) {
    const newState = { ...this.state.value };

    if (item instanceof Knot) {
      if (newState.knots.some(knot => knot.name === item.name)) { return; }
      newState.knots.push(item);
    } else if (item instanceof Tag) {
      if (newState.tags.some(tag => tag.name === item.name)) { return; }
      newState.tags.push(item);
    } else if (item instanceof Connection) {
      if (newState.connections.some(connection => connection.name === item.name)) { return; }
      newState.connections.push(item);
    } else {
      return;
    }

    this.state.next(newState);
    this.sync();
  }

  removeKnowledgeItem(item: Knot | Tag | Connection) {
    const newState = { ...this.state.value };

    if (item instanceof Knot) {
      newState.knots = newState.knots.filter(knot => knot.name !== item.name);
    } else if (item instanceof Tag) {
      newState.tags = newState.tags.filter(tag => tag.name !== item.name);
    } else if (item instanceof Connection) {
      newState.connections = newState.connections.filter(connection => connection.name !== item.name);
    } else {
      return;
    }

    this.state.next(newState);
    this.sync();
  }

  search(query: string) {
    this.state.next({ ...this.state.value, query });
    this.sync();
  }

  getState() {
    return this.state.asObservable().pipe(filter(value => !!value));
  }

  getStateSync() {
    return this.state.getValue();
  }

  setState(state: AdvancedSearchState) {
    this.state.next(state);
    this.sync();
  }

  sync() {
    this.store.set(this.state.value, this.variant);
  }

  getSuggestions(query: string): Observable<DropdownSelectItem<string>[]> {
    const params = {
      query: query,
      limit: 5
    };

    return this.http.get<{ suggestions: string[] }>(`${environment.baseUrl}/api/search/suggestions`, { params })
      .pipe(
        map(({ suggestions }) => suggestions
          .map(suggestion => ({ title: suggestion, value: suggestion, source: suggestion, type: 'suggestion' }))
        ),
        catchError(error => this.handleObserverError(error))
      );
  }

  getApplied() {
    return this.state
      .pipe(
        map(state => (
          state.query?.trim() !== '' ||
          state.knots.length > 0 ||
          state.tags.length > 0 ||
          state.connections.length > 0
        ))
      );
  }

  private handleObserverError(error: HttpErrorResponse) {
    this.toasterService.show({ text: error?.error?.message || error?.error?.error || error?.message });
    return throwError(error);
  }
}

// Common
import { isNil } from 'lodash';
import { Like } from './like';

// Types
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Application } from './application';
import { ChildStitch } from './child-stitch';
import { StitchFilters } from './stitch-filters';

export abstract class StitchChildFilters<S extends ChildStitch> extends StitchFilters<S> {
  containersIds?: string[];
  noContainer?: boolean;

  constructor(filters?: Like<StitchChildFilters<S>>) {
    super(filters);
    this.containersIds = filters?.containersIds;
    this.noContainer = filters?.noContainer;
  }

  applyAdvancedFilters(advancedState: AdvancedSearchState, application?: Application) {
    super.applyAdvancedFilters(advancedState);

    const appState = advancedState[application];

    this.containersIds = appState.containersIds;
    this.noContainer = !appState.containersIds.length;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['containers_ids[]'] = this.containersIds; }
    if (!isNil(this.noContainer)) { result['no_container'] = this.noContainer + ''; }

    return result;
  }
}

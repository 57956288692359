import { Inject, Injectable } from "@angular/core";
import { ListStateService } from "@modules/common/components/list/list-state.service";
import { AdvancedSearchService } from "@modules/search/services/advanced-search.service";
import { StateService } from "@modules/settings/services/state.service";
import { MessageFolderListStateServiceConfig, MessageFolderListStateServiceConfigToken } from "../types/MessageFolderListStateServiceConfig";
import { FoldersListState } from "../types/folders-list-state";
import { type MessageFolder } from "../types/message-folder";

@Injectable()
export class MessageFolderListStateService extends ListStateService<MessageFolder, FoldersListState> {

  public override readonly state: FoldersListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(MessageFolderListStateServiceConfigToken) config: MessageFolderListStateServiceConfig
  ) {
    super();

    this.state = new FoldersListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch
    });
  }

  static providers(config: MessageFolderListStateServiceConfig = {}): any[] {
    return super.prepareProviders(MessageFolderListStateServiceConfigToken, config);
  }
}
// Common
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

// RX
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SelectableService } from '../services/selectable.service';

// Types
import { SelectableItem } from '../types/selectable-item';

@Directive({
  standalone: true,
  selector: '[appSelectable]'
})
export class SelectableItemDirective implements AfterViewInit, OnChanges, OnDestroy {

  @HostBinding('attr.app-selectable-item')

  // Private
  private alive = new Subject<void>();

  // Inputs
  @Input() appSelectable: unknown;
  @Input() appSelectablePosition: number;
  @Input() appSelectableIdentifier: number | string;

  // Outputs
  @Output() select = new EventEmitter<[number, MouseEvent]>();

  // Public
  public data: SelectableItem = null;

  /**
   * Constructor
   */

  constructor(
    public element: ElementRef,
    private ngZone: NgZone,
    private selectableService: SelectableService
  ) {
    element.nativeElement.component = this;
  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.element.nativeElement, 'click')
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) => {
          this.select.emit([this.appSelectablePosition, event]);
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('appSelectable' in changes) {
      this.data = {
        data: this.appSelectable,
        position: this.appSelectablePosition,
        identifier: this.appSelectableIdentifier
      };
      this.selectableService.triggerUpdate();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}

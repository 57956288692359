<app-list-load-error [error]="loadingError" (retry)="resetItems()"></app-list-load-error>

<app-scrollable
  [loading]="loading"
  [canAutoLoad]="hasMore"
  [autoloadThreshold]="Constants.SCROLL_AUTO_LOAD_THRESHOLD"
  [belowContentTemplate]="belowContentTemplate"
  [footerTemplate]="footerTemplate"
  (loadNext)="loadMore()"

  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  @for (item of items; track item.data.id; let index = $index) {
    @if (item.showSectionHeader && item.section) {
      <app-list-items-separator>
        {{ item.section | date: Constants.DATE_DEFAULT_FORMAT }}
      </app-list-items-separator>
    }

    <app-message-folder
      [item]="item.data"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [position]="index"
      [debug]="debug"
      (open)="openFolder.emit(item.data)"
      (openMessage)="openMessage.emit($event)"
      (appClick)="handleClickItem(item.data)"
      (appDblClick)="handleDoubleClickItem(item.data)"
      [appSelectable]="item.data"
      [appSelectablePosition]="index"
    ></app-message-folder>
  }
</app-scrollable>

<ng-template #belowContentTemplate>
  <app-loading-indicator [hidden]="!loading">Loading folders...</app-loading-indicator>
</ng-template>
<ng-template #footerTemplate>
  @if (items?.length > 0) {
    <app-list-items-count [showing]="items.length" [total]="totalCount"/>
  }
</ng-template>

@if (items?.length === 0 && !loading && !loadingError) {
  <app-no-content-stub imageSrc="assets/placeholders/placeholder-tasks.svg">
    You have no folders
  </app-no-content-stub>
}
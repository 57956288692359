// Common
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Types
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';
import { FoldersListState } from '@modules/messages/types/folders-list-state';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { SortOrder } from '@modules/common/types/sort-order';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-folders-list-context-menu',
  templateUrl: './folders-list-context-menu.component.html',
  styleUrls: ['./folders-list-context-menu.component.less']
})
export class FoldersListContextMenuComponent implements OnInit, OnDestroy {

  // Input
  @Input() state: FoldersListState;
  @Input() withAdvancedSearch: boolean;
  @Input() withScore: boolean;

  // Public
  public advancedSearchApplied: boolean;

  // Private
  private alive = new Subject<void>();

  constructor(
    private advancedSearchService: AdvancedSearchService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.advancedSearchService.getApplied()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(state => {
        this.advancedSearchApplied = state;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  sort(sortBy: 'title' | 'createdAt' | 'updatedAt') {
    let order = this.state.sortOrder;
    let by = this.state.sortBy;

    if (by === sortBy) {
      order = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    } else {
      by = sortBy;
      order = SortOrder.ASC;
    }

    this.state.sortBy = by;
    this.state.sortOrder = order;
    this.state.notifyStateChanged();
  }

  pinnedOnTop() {
    this.state.pinnedOnTop = !this.state.pinnedOnTop;
    this.state.notifyStateChanged();
  }

  flaggedOnTop() {
    this.state.flaggedOnTop = !this.state.flaggedOnTop;
    this.state.notifyStateChanged();
  }

  flag() {
    this.state.flagged = this.state.flagged ? null : true;
    this.state.notifyStateChanged();
  }

  pin() {
    this.state.pinned = this.state.pinned ? null : true;
    this.state.notifyStateChanged();
  }

  stitch(stitch: StitchTypeFilters) {
    this.state.stitchedWith = stitch;
    this.state.notifyStateChanged();
  }
}

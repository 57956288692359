import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, debounceTime, filter, Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-list-items-count',
  imports: [AsyncPipe],
  templateUrl: './list-items-count.component.html',
  styleUrl: './list-items-count.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemsCountComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) public showing: number;
  @Input({ required: true }) public total: number;

  public readonly show = new BehaviorSubject(true);
  private readonly alive = new Subject<void>();

  constructor() {
    this.show
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.alive)
      ).subscribe(() => {
        this.show.next(false);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("total" in changes) {
      this.show.next(true);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
import { InjectionToken } from "@angular/core";
import { type SortBy } from "@modules/common/types/SortBy";
import { type StateKey } from "@modules/settings/types/state-key";
import { type MessagesListSortField } from "./messages-list-state";

export interface MessageListStateServiceConfig {
  stateKey?: StateKey;
  withAdvancedSearch?: boolean;
  defaultSort?: SortBy<MessagesListSortField>;
}

export const MessageListStateServiceConfigToken = new InjectionToken<MessageListStateServiceConfig>(
  'Configuration object for message list state service'
);
// Types
import { FoldersListState } from './folders-list-state';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { VirtualFolder } from './virtual-folder';
import { MessageFolder } from './message-folder';

export class FoldersFilters extends StitchFilters<MessageFolder> {
  // Sort Options
  sortBy?: 'title' | 'score' | 'createdAt' | 'updatedAt';

  constructor (filters?: Like<FoldersFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
  }

  applyListState(state: FoldersListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.mail;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = state.subject;
    this.esBody = state.body;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = false;
    this.followedOnTop = false;

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'all_messages':
      case 'bulk':
      case 'sent':
      case 'spam':
      case 'draft':
      case 'scheduled':
      case 'all_folders':
        this.archived = false;
        this.deleted = false;
        this.snoozedOnTop = true;
        this.followedOnTop = true;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}

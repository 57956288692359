<div
  class="item"
  [ngClass]="[appearance, size]"
  [class.disabled]="disabled"
  (click)="handleClick($event)"
  stchPopover
  [stchPopoverContent]="popoverContent"
  stchPopoverPlacement="right"
  [stchPopoverFallbackPlacements]="['right', 'left']"
  [stchPopoverDisabled]="!hasNextLevel || disabled"
  stchPopoverTrigger="click"
  [stchPopoverArrow]="arrow"
  (stchPopoverVisibleChange)="popoverOpened = $event"
>
  <div
    class="item-check"
    [class.no-check-option]="!withCheckOption"
  >
    <mat-icon
      *ngIf="active && withCheckOption"
      svgIcon="check"
      class="item-check-icon"
    />
  </div>

  <div *ngIf="icon" class="item-custom-icon">
    <mat-icon
      [svgIcon]="activeIcon && active ? activeIcon : icon"
      class="item-custom-icon-icon"
      [class.active]="active"
    ></mat-icon>
  </div>

  <div
    class="item-text"
    [class.active]="active"
    [class.dangerous]="dangerous"
  >
    {{ title }}
  </div>

  <div *ngIf="badge" class="item-badge">{{ active ? 1 : secondary ? 2 : '' }}</div>

  <mat-icon
    *ngIf="sortable && (active || secondary)"
    svgIcon="arrow-up"
    class="item-arrow-icon"
    [class.active]="active"
    [class.reversed]="reversed"
  ></mat-icon>

  <mat-icon
    *ngIf="hasNextLevel"
    svgIcon="dropdown-right"
    class="item-dropdown-icon"
    [class.active]="popoverOpened"
  ></mat-icon>
</div>

<ng-template #popoverContent>
  <div
    #popoverContainer
    class="popover-container"
  >
    <ng-content></ng-content>
  </div>
</ng-template>

<div style="display: none">
  <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
</div>

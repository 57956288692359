// Common
import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Injector, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { relativeDateToDate } from '@modules/common/utils/date';

// Types
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Message } from '@modules/messages/types/message';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';

// RxJS
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Services
import { PopoverService } from '@modules/popover/services/popover.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { AlertService } from '@modules/alert/services/alert.service';

@Component({ template: '' })
export abstract class BaseStitchComponent<StitchItem extends Stitch> implements OnInit, OnChanges, OnDestroy {
  @Input() item: StitchItem;
  @Input() contextMenuEnabled = true;
  @Input() dragEnabled = true;
  @Input() actionsButtonEnabled = true;
  @Input() short = false;
  @Input() withBar = true;
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() position: number;
  @Input() debug: 'debug';

  @Output() open = new EventEmitter();
  @Output() onClick = new EventEmitter<StitchItem>();
  @Output() onDoubleClick = new EventEmitter<StitchItem>();

  public abstract dndPredicate: (dragData: DragData) => boolean;
  public isDragging = false;
  public contextMenuOpened = false;
  public dragData: { data: StitchItem[], type: DragDataTypes };
  public selected = false;
  public barExpanded = false;

  protected abstract moveDragDataTypes: DragDataTypes[];
  protected abstract dragDataType: DragDataTypes;
  protected popoverClose = new Subject<void>();
  protected alive: Subject<void> = new Subject();
  protected changed = new Subject<void>();

  protected changeDetector: ChangeDetectorRef;
  protected elementRef: ElementRef;
  protected selectableService: SelectableService;
  protected stitchService: StitchService;
  protected messagesStateService: MailAppStateService;
  protected popoverService: PopoverService;
  private alertService = inject(AlertService)

  constructor(
    private injector: Injector,
    protected stitchItemsService: BaseStitchService<StitchItem, StitchFilters<StitchItem>>,
  ) {
    this.changeDetector = injector.get(ChangeDetectorRef);
    this.elementRef = injector.get(ElementRef);
    this.selectableService = injector.get(SelectableService, undefined, { optional: true });
    this.stitchService = injector.get(StitchService);
    this.messagesStateService = injector.get(MailAppStateService);
    this.popoverService = injector.get(PopoverService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dragData ||= { data: [this.item], type: this.dragDataType };

    this.selectableService && this.changed
      .pipe(
        switchMap(() => this.selectableService.getDragData(this.item, this.position)),
        takeUntil(this.alive)
      )
      .subscribe((data: StitchItem[]) => this.dragData = { data, type: this.dragDataType });

    this.selectableService && this.changed
      .pipe(
        switchMap(() => this.selectableService.getSelected(this.position)),
        takeUntil(this.alive)
      )
      .subscribe(selected => {
        this.selected = selected;
        this.changeDetector.detectChanges();
      });

    this.changed.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes || 'position' in changes) {
      this.changed.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  pin(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.stitchItemsService.pin({ ids: [this.item.id] }, !this.item.pinned)
      .pipe(takeUntil(this.alive));
    this.popoverClose.next();
  }

  flag(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    this.stitchItemsService.flag({ ids: [this.item.id] }, !this.item.flagged)
      .pipe(takeUntil(this.alive));
    this.popoverClose.next();
  }

  archive() {
    this.stitchItemsService.archive({ ids: [this.item.id] }, !this.item.archived)
      .pipe(takeUntil(this.alive));
  }

  delete() {
    this.item.deleted ?
      this.stitchItemsService.deletePermanently({ ids: [this.item.id] }) :
      this.stitchItemsService.delete({ ids: [this.item.id] }, true);
  }

  snooze() {
    const date = this.item.snoozed ? null : new Date(Date.now() + 5 * 60 * 1000);

    this.stitchItemsService.snooze({ ids: [this.item.id] }, date );
    this.popoverClose.next();
  }

  followUp() {
    const date = this.item.followed ? null : new Date(relativeDateToDate('tomorrow').setHours(9, 0, 0, 0));

    this.stitchItemsService.followUp({ ids: [this.item.id] }, date);
    this.popoverClose.next();
  }

  createMail(event?: MouseEvent) {
    event?.stopPropagation();
    event?.preventDefault();

    this.messagesStateService.composeMessage({ message: new Message(this.item), injector: this.injector });
  }

  public dndDrop(dragData: DragData) {
    if (!this.moveDragDataTypes.includes(dragData.type)) {
      return this.handleStitch(dragData);
    }

    this.alertService.show({
      title: 'Move or Stitch?',
      body: 'Move or Stitch?',
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'Move',
          click: () => this.handleMove(dragData),
          close: true
        },
        {
          title: 'Stitch',
          click: () => this.handleStitch(dragData),
          close: true
        }
      ]
    });
  }

  public abstract handleMove(dragData: DragData): void;

  public handleStitch(dragData: DragData) {
    this.stitchService.linkDragData(this.item, dragData);
  }

  protected getIds(items: (Stitch | Tag | Knot | Connection)[]): string[] {
    return items.map(({ id }) => id);
  }

}
